<template>
    <div class="h-100vh d-flex" :class="{ 'webview-purple-blur-bg': store.step == 2 }" :style="{ paddingTop: paddingTop(), paddingBottom: paddingBottom() }">
        <div class="container px-16px flex-fill overflow-hidden position-relative">
            <div class="h-100 d-flex flex-column">
                <div class="mb-4 d-flex align-items-center justify-content-between">
                    <back-button :text="langStore.lang?.views?.back" @click="store.prevStep" />
                    <close-button @click="createUpdateUrlParam('exit', true)" />
                </div>


                <div class="flex-fill">
                    <ActivityList v-if="store.step == 1" />
                    <AddActivity v-if="store.step == 2" />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import { usePreSurgeryChecklistStore } from '../../../stores/pre_surgery_checklist';
import { useLangStore } from '../../../stores/lang';
import BackButton from '../../BackButton.vue';
import CloseButton from '../../CloseButton.vue';
import ActivityList from './ActivityList.vue';
import AddActivity from './AddActivity.vue';
import { paddingTop, paddingBottom } from '../../../utils/webview_padding_helper';

const store = usePreSurgeryChecklistStore();
const langStore = useLangStore();

const props = defineProps([
    'preSurgeryChecklistItems',
    'lang',
])

if (props.preSurgeryChecklistItems != 'null') {
    store.formData = JSON.parse(props.preSurgeryChecklistItems)
}

langStore.lang = JSON.parse(props.lang)

</script>