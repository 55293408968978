import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="discharge-consent-modal"
export default class extends Controller {

  static targets = ['details', 'read', 'close']

  connect() {
    this.element.addEventListener('turbo:submit-end', () => this.hideModal())
  }

  toggleDetails(){
    this.readTarget.classList.toggle('d-none')
    this.closeTarget.classList.toggle('d-none')
    this.detailsTarget.classList.toggle('d-none')
  }

  hideModal(){
    const button = document.getElementById('close-discharge-consent-modal')
    button.click();
  }
}
