<template>
    <div class="flex-fill">
        <div class="d-flex flex-column h-100">
            <div class="text-center mb-4">
                <img :src="getImageURL('pharmacist.svg')">
            </div>

            <div class="mb-2">
                <Title :text="langStore.lang.medications.webview.tell_us_medication_name" />
            </div>

            <div class="mb-5 d-flex justify-content-center">
                <MultipartProgressBar :parts="2" :active="2" color="#b8a1ff" active-color="#7345FE"/>
            </div>

            <div>
                <textarea class="form-control" rows="3" v-model="store.formData.desc" :placeholder="langStore.lang.medications.webview.write_details_here"></textarea>
            </div>

            <div class="mt-auto">
                <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="store.nextStep()">{{ langStore.lang.views.continue }}</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMedicationsStore } from '../../../stores/medications';
import { useLangStore } from '../../../stores/lang';
import { getImageURL } from '../../../utils/image_url_helper';
import Title from '../Title.vue';
import MultipartProgressBar from '../../MultipartProgressBar.vue';

const store = useMedicationsStore();
const langStore = useLangStore();

</script>