<template>
    <div class="flex-fill">
        <div class="d-flex flex-column h-100">
            <div class="text-center mb-4">
                <img :src="getImageURL('pharmacist.svg')">
            </div>

            <div class="mb-2">
                <Title :text="langStore.lang.medications.webview.tell_us_medication_name" />
            </div>

            <div class="mb-4 d-flex justify-content-center">
                <MultipartProgressBar :parts="2" :active="1" color="#b8a1ff" active-color="#7345FE"/>
            </div>

            <div>
                <div class="mb-4">
                    <div class="fs-18px text-gray11 mb-1 fw-300">{{ langStore.lang.medications.webview.drug_name }}</div>
                    <input type="text" class="form-control" v-model="store.formData.name">
                </div>
                <div class="mb-4">
                    <div class="fs-18px text-gray11 mb-1 fw-300">{{ langStore.lang.medications.webview.write_dosage }}</div>
                    <input type="text" class="form-control" :placeholder="langStore.lang.medications.webview.mg_or_ml" v-model="store.formData.dose">
                </div>
                <div>
                    <div class="fs-18px text-gray11 mb-1 fw-300">{{ langStore.lang.medications.webview.select_frequency }}</div>
                    <select class="form-select" v-model="store.formData.frequency">
                        <option :value="null">{{ langStore.lang.medications.webview.how_often }}</option>
                        <option v-for="option in store.frequencyOptions" :value="option?.value">{{ option?.label }}</option>   
                    </select>
                </div>
            </div>

            <div class="mt-auto">
                <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="store.nextStep" :disabled="!allFieldsFilled">{{ langStore.lang.views.continue }}</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMedicationsStore } from '../../../stores/medications';
import { useLangStore } from '../../../stores/lang';
import { getImageURL } from '../../../utils/image_url_helper';
import Title from '../Title.vue';
import { computed } from 'vue';
import MultipartProgressBar from '../../MultipartProgressBar.vue';

const store = useMedicationsStore();
const langStore = useLangStore();

const allFieldsFilled = computed(() => {
    return store.formData.name && store.formData.dose && store.formData.frequency
})
</script>