<template>
    <div class="min-h-100vh d-flex" :class="blueBackground ? 'blue-bg' : 'webview-purple-blur-bg'" :style="{ paddingTop: paddingTop(), paddingBottom: paddingBottom() }">
        <div class="container px-16px flex-fill overflow-hidden position-relative">
            <div class="h-100 d-flex flex-column">
                <div class="mb-4 d-flex align-items-center justify-content-between">
                    <back-button :text="langStore.lang?.views?.back" @click="store.prevStep" v-if="store.step != 5"/>
                    <close-button class="ms-auto" @click="createUpdateUrlParam('exit', true)" />
                </div>

                <AddMedication v-if="store.step == 1" />
                <MedicationDetails v-if="store.step == 2" />
                <MedicationDesc v-if="store.step == 3" />
                <ConfirmMedication v-if="store.step == 4" />
                <Outro v-if="store.step == 5" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import { useMedicationsStore } from '../../../stores/medications';
import { useLangStore } from '../../../stores/lang';
import BackButton from '../../BackButton.vue';
import CloseButton from '../../CloseButton.vue';
import AddMedication from './AddMedication.vue';
import MedicationDetails from './MedicationDetails.vue';
import MedicationDesc from './MedicationDesc.vue';
import ConfirmMedication from './ConfirmMedication.vue';
import { computed } from 'vue';
import Outro from './Outro.vue';
import { paddingTop, paddingBottom } from '../../../utils/webview_padding_helper';

const store = useMedicationsStore();
const langStore = useLangStore();

const props = defineProps([
    'medications',
    'lang',
    'frequencyOptions',
])

if (props.medications != '') {
    store.medications = JSON.parse(props.medications)
}

langStore.lang = JSON.parse(props.lang)
store.frequencyOptions = JSON.parse(props.frequencyOptions)

const blueBackground = computed(() => {
    return [4, 5].includes(store.step)
})
</script>
<style scoped>
.blue-bg {
    background: #D4E8FF;
}
</style>