import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="who-checklist"
export default class extends Controller {
  connect() {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === 'value') {
              this.checkSectionsCompletion()
            }
        });
    });

    const inputs = document.querySelectorAll('input')

    inputs.forEach(input => observer.observe(input, { attributes: true }))

    this.checkSectionsCompletion()

    document.addEventListener('turbo:submit-end', () => window.scrollTo(0, 0))
  }

  checkSectionsCompletion(){
    this.updateProgressBar();
  }

  updateProgressBar(){
    const answered = this.checkSection1() + this.checkSection2() + this.checkSection3() + this.checkSection4();
    const total = 16;
    const completedPercent = answered / total * 100;

    const progressBar = this.element.querySelector('.purple-progress-bar .item')
    progressBar.style.width = completedPercent+'%'
  }

  checkSection1(){
    const buttonInputs = this.element.querySelectorAll('#check-1 input[type="hidden"]')
    const textInput = this.element.querySelector('input[name="operating_surgeon"]')

    let total = Array.from(buttonInputs).filter(input => ['0', '1'].includes(input.value)).length

    if(textInput.value != ''){
      total++;
    }

    return total;
  }

  checkSection2(){
    const buttonInputs = this.element.querySelectorAll('#check-2 input[type="hidden"]')
    const textInput = this.element.querySelector('input[name="care_staff"]')

    let total = Array.from(buttonInputs).filter(input => ['0', '1'].includes(input.value)).length

    if(textInput.value != ''){
      total++;
    }

    return total;
  }

  checkSection3(){
    const buttonInputs = this.element.querySelectorAll('#check-3 input[type="hidden"]')
    const textInput = this.element.querySelector('input[name="senior_member"]')

    let total = Array.from(buttonInputs).filter(input => ['0', '1'].includes(input.value)).length

    if(textInput.value != ''){
      total++;
    }

    return total;
  }

  checkSection4(){
    const buttonInputs = this.element.querySelectorAll('#check-4 input[type="hidden"]')
    const textInput = this.element.querySelector('input[name="theatre_staff"]')
    const checkboxes = this.element.querySelectorAll('input[name="patient_seen_by"]')

    let total = Array.from(buttonInputs).filter(input => ['0', '1'].includes(input.value)).length

    if(textInput.value != ''){
      total++;
    }

    if(Array.from(checkboxes).filter(input => input.checked).length > 0){
      total++;
    }

    return total;
  }
}
