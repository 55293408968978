<template>
    <div>
        <div class="mb-4">
            <Title :text="langStore.lang.habits.webview.last_drank_at"/>
        </div>
        <div class="mb-4">
            <div class="fs-18px text-gray11 mb-1 fw-300">{{ langStore.lang.views.select_date }}</div>
            <input type="date" class="form-control mb-3" :max="moment().format('YYYY-MM-DD')" v-model="store.formData.last_drank_at" @blur="select">
        </div>
        <div class="fs-16px color-light-blue2 ff-montserat text-center">
            {{ langStore.lang.habits.webview.last_smoked_at_desc }}
        </div>
    </div>
</template>
<script setup>
import moment from 'moment'
import { useHabitsStore } from '../../../stores/habits';
import { useLangStore } from '../../../stores/lang';
import Title from '../Title.vue';

const store = useHabitsStore();
const langStore = useLangStore();

const select = () => {
    if(store.formData.last_drank_at){
        store.nextStep()
    }
}
</script>