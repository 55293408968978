import { defineStore } from "pinia/dist/pinia.cjs";
import { ref } from "vue";
import { createUpdateUrlParam } from '../utils/create_update_url_param';

export const useSurgeryPreparationStore = defineStore('surgery-preparation', () => {
    const step = ref(1)

    const nextStep = () => {
        step.value++
    }

    const prevStep = () => {
        if (step.value == 1) {
            createUpdateUrlParam('exit', true)
            return;
        }
        
        step.value--
    }

    return {
        step,
        nextStep,
        prevStep
    }
})