<template>
    <div class="advice-card ff-montserat">
        <div v-html="props.text"></div>
        <div class="d-flex align-items-end justify-content-between">
            <div>
                <div class="fs-11px">{{ langStore.lang.habits.webview.quality_of_evidence }}: <span class="text-success">{{ langStore.lang.views.moderate }}</span></div>
                <div class="fs-11px">{{ langStore.lang.habits.webview.recommendation_grade }}: <span class="text-orange">{{ langStore.lang.views.strong }}</span></div>
            </div>
            <div class="d-flex align-items-end">
                <img :src="getImageURL('pharmacist--health-wellness-med-pharmacist.svg')">
                <a href="javascript:;" class="ms-2" v-if="props.openUrl" @click="createUpdateUrlParam('openUrl', props.openUrl)">
                    <img :src="getImageURL('purple-question-mark.svg')" >
                </a>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useLangStore } from '../../../stores/lang';
import { getImageURL } from '../../../utils/image_url_helper';
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';

const langStore = useLangStore()

const props = defineProps([
    'text',
    'openUrl'
])
</script>

<style scoped>
.advice-card {
    color: #757575;
    background: white;
    padding: 15px;
    border-radius: 19px;
}
</style>