<template>
    <div class="advice-card ff-montserat">
        <div v-html="props.text"></div>
        <div class="d-flex align-items-end justify-content-end">
            <img :src="getImageURL('pharmacist--health-wellness-med-pharmacist.svg')">
        </div>
    </div>
</template>
<script setup>
import { getImageURL } from '../../../utils/image_url_helper';

const props = defineProps([
    'text',
    'openUrl'
])
</script>

<style scoped>
.advice-card {
    color: #757575;
    background: white;
    padding: 15px;
    border-radius: 19px;
}
</style>