<template>
    <div class="flex-fill d-flex flex-column">
        <div class="text-center mb-4">
            <img :src="getImageURL('pharmacist-with-star.svg')">
        </div>
        <div class="text-center mb-5">
            <Title :text="langStore.lang.medications.webview.outro_title" />
        </div>
        <div class="text-dark-blue fs-18px ff-montserat">{{ langStore.lang.medications.webview.outro_text }}</div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="submit">{{ langStore.lang.views.back_to_preop }}</button>
        </div>

        <SuccessAnimation ref="success" :callback="() => createUpdateUrlParam('completed', true)" v-show="showSuccess"/>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useLangStore } from '../../../stores/lang';
import { useMedicationsStore } from '../../../stores/medications';
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import { getImageURL } from '../../../utils/image_url_helper';
import SuccessAnimation from '../../SuccessAnimation.vue';
import Title from '../Title.vue';

const langStore = useLangStore();

const success = ref(null)
const showSuccess = ref(false)

const submit = () => {
    showSuccess.value = true
    success.value.animation.play()
}
</script>