<template>
    <div>
        <div class="mb-4">
            <Title :text="langStore.lang.habits.webview.cigarette_daily_count"/>
        </div>
        <div class="mb-4">
            <div class="fs-18px text-gray11 mb-1 fw-300">{{ langStore.lang.views.type_number }}</div>
            <input type="number" class="form-control" v-model.number="store.formData.cigarette_daily_count" @keydown="sanitizeNumberInput($event, 3)" @blur="handleInput">
        </div>
        <div class="fs-16px color-light-blue2 ff-montserat text-center">
            {{ langStore.lang.habits.webview.cigarette_daily_count_desc }}
        </div>
        <HoverMessage 
            v-if="showHoverMessage"
            image="smoking-advice-image.svg" 
            :text="langStore.lang.habits.webview.smoking_hover_msg_text" 
            :title="langStore.lang.habits.webview.smoking_hover_msg_title"/>
    </div>
</template>
<script setup>
import { watch } from 'vue';
import { useHabitsStore } from '../../../stores/habits';
import { useLangStore } from '../../../stores/lang';
import Title from '../Title.vue';
import HoverMessage from './HoverMessage.vue';
import { ref } from 'vue';
import { sanitizeNumberInput } from '../../../utils/sanitize_input';

const store = useHabitsStore();
const langStore = useLangStore();

const showHoverMessage = ref(false)

const handleInput = () => {
    const val = store.formData.cigarette_daily_count

    if(Number(val) > 5){
        showHoverMessage.value = true
        return;
    }
    if(val !== null && val !== '' && val != 'e'){
        store.nextStep()
    }
}
</script>