import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="patient-pmh"
export default class extends Controller {
  connect() {
    this.checkedInputs = 0;
    this.mandatoryInputs = 5;

    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === 'value') {
              this.checkCompletion()
            }
        });
    });

    const inputs = this.element.querySelectorAll('input')

    inputs.forEach(input => observer.observe(input, { attributes: true }))

    this.checkCompletion()

    document.addEventListener('turbo:submit-end', () => window.scrollTo(0, 0))
  }

  checkCompletion(){
    this.updateIndicator();
    this.updateProgressBar();
  }

  updateIndicator(){
    const indicator = this.element.querySelector('.completion-indicator');
    const buttonInputs = this.element.querySelectorAll('#pmh input[type="hidden"]')

    this.checkedInputs = 0;

    buttonInputs.forEach(input => {
      if(['0', '1'].includes(input.value)){
        this.checkedInputs++;
      }
    })

    const allChecked = this.checkedInputs == this.mandatoryInputs

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  updateProgressBar(){
    const completedPercent = this.checkedInputs / this.mandatoryInputs * 100;

    const progressBar = this.element.querySelector('.purple-progress-bar .item')
    progressBar.style.width = completedPercent+'%'
  }
}
