import { defineStore } from "pinia/dist/pinia.cjs";
import { ref } from "vue";
import { createUpdateUrlParam } from '../utils/create_update_url_param';
import ApiService from "../services/api_service";

export const useAllergiesStore = defineStore('allergies', () => {
    const step = ref(1)

    const allergies = ref([])
    const severityOptions = ref([])

    const formData = ref({
        allergy: null,
        severity: 1,
    })

    const resetFormData = () => {
        formData.value = {
            allergy: null,
            severity: 1,
        }
    }

    const nextStep = () => {
        step.value++
    }

    const prevStep = () => {
        if (step.value == 1) {
            createUpdateUrlParam('exit', true)
            return;
        }
        
        step.value--
    }

    const save = (token) => {
        return new Promise((resolve, reject) => {
            ApiService.post('/p/allergies', { allergies: allergies.value, token: token }).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    const getSeverityName = (severity) => {
        return severityOptions.value.find(item => item.value == severity)?.label
    }

    const destroy = (id, name, token) => {
        if(!id){
            const index = allergies.value.indexOf(item => item.allergy == name)
            allergies.value.splice(index, 1)
            return;
        }

        return new Promise((resolve, reject) => {
            ApiService.delete('/p/allergies/'+id+'?token='+token).then(({ data }) => {
                const index = allergies.value.indexOf(item => item.id == id)
                allergies.value.splice(index, 1)
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    return {
        step,
        allergies,
        formData,
        nextStep,
        prevStep,
        save,
        resetFormData,
        severityOptions,
        getSeverityName,
        destroy
    }
})