import { Controller } from "@hotwired/stimulus"
// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Connects to data-controller="pre-op"
export default class extends Controller {
  connect() {
    this.slides = {
      'clinical_team_tasks': 1,
      'patient_info': 0
    }

    this.swiper = new Swiper('.mySwiper', {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".swiper-pagination",
      }
    });

    const anchor = this.getAnchor();

    if(anchor != null && Object.keys(this.slides).includes(anchor)){
      this.goToSlide(this.slides[anchor])
    }
  }

  handleSlideChange(e){
    const slide = e.target.getAttribute('data-slide');
    this.goToSlide(slide)
  }

  goToSlide(slide){
    const tabs = document.querySelectorAll('.pre-op-tab')

    tabs.forEach(tab => tab.classList.remove('active'))
    document.querySelector('a[data-slide="'+slide+'"]').classList.add('active')
    
    this.swiper.slideTo(slide)
  }

  getAnchor() {
      const currentUrl = document.URL,
      urlParts = currentUrl.split('#');
      
      return (urlParts.length > 1) ? urlParts[1] : null;
  }
}
