<template>
    <div>
        <div class="mb-2">
            <Title :text="langStore.lang.discharge_plan.webview.community_support_html"/>
        </div>

        <div class="mb-5 fs-16px text-dark-blue">{{ langStore.lang.discharge_plan.webview.community_support_desc }}</div>

        <div class="d-flex justify-content-center">
            <YesNoWithThumbs 
                v-model="store.formData.community_support"
                @selected="selected"
                bg-color="#ffffff"
                text-color="#1F2E99"
                active-bg="#D8CBFE"
                active-text="#1F2E99"
                :yes-label="langStore.lang.views.yes_label"
                :no-label="langStore.lang.views.no_label"
            />
        </div>
    </div>
</template>
<script setup>
import { useDischargeConsentStore } from '../../../stores/discharge_consent';
import { useLangStore } from '../../../stores/lang';
import YesNoWithThumbs from '../../YesNoWithThumbs.vue';
import Title from '../Title.vue';

const store = useDischargeConsentStore();
const langStore = useLangStore();


const selected = val => {
    store.nextStep()
}
</script>