<template>
    <div class="h-100 d-flex flex-column">
        <div class="fs-32px lh-32px text-primary fw-500 ff-montserat mb-3">{{ langStore.lang.pre_surgery_checklist.webview.prepare_for_surgery }}</div>
        <div class="text-dark-blue fs-18px lh-20px ff-montserat mb-4">{{ langStore.lang.pre_surgery_checklist.webview.desc }}</div>

        <div>
            <div class="d-flex justify-content-between align-items-center mb-3" v-for="activity in store.activities" @click="markAsComplete(activity.id)">
                <div class="white-box p-3 flex-fill text-dark-blue fs-16px ff-montserat fw-600">{{ activity.name }}</div>
                <div class="ms-3"> 
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="activity.completed">
                        <circle cx="20" cy="20" r="20" fill="#6DD803"/>
                        <path d="M28 15L17 26L12 21" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" v-else>
                        <circle cx="20" cy="20" r="17" stroke="#F6F6F6" stroke-width="6"/>
                    </svg>
                </div>
            </div>
        </div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="store.nextStep">{{ langStore.lang.pre_surgery_checklist.webview.add_new }}</button>
        </div>
    </div>
</template>
<script setup>
import { usePreSurgeryChecklistStore } from '../../../stores/pre_surgery_checklist';
import { useLangStore } from '../../../stores/lang';

const store = usePreSurgeryChecklistStore();
const langStore = useLangStore();

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

store.getActivities(token)

const markAsComplete = (id) => {
    store.markAsComplete(id, token).then(() => {
        store.getActivities(token)
    })
}
</script>
<style scoped>
.white-box{
    border-radius: 16px;
    background:white;
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.04);
    border: 1px solid #F6F6F6;
}
</style>