<template>
    <div class="d-flex webview-multipart-progress-bar">
        <div class="item me-3" :class="part == props.active ? 'active' : ''" :style="{ background: part == props.active ? props.activeColor : props.color }" v-for="part in props.parts"></div>
    </div>
</template>
<script setup>
const props = defineProps([
    'parts',
    'active',
    'activeColor',
    'color'
])
</script>