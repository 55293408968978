<template>
    <div>
        <div class="mb-4">
            <Title :text="langStore.lang.habits.webview.alcohol_weekly_count"/>
        </div>
        <div class="mb-4">
            <div class="fs-18px text-gray11 mb-1 fw-300">{{ langStore.lang.views.type_number }}</div>
            <input type="number" class="form-control" v-model.number="store.formData.alcohol_weekly_count" @keydown="sanitizeNumberInput($event, 3)" @blur="handleInput">
        </div>
        <div class="p-2 input-border-radius border-light border">
            <div class="d-flex justify-content-between">
                <div class="fs-16px color-light-blue2 ff-montserat mb-3">
                    {{ langStore.lang.habits.webview.alcohol_weekly_count_desc }}
                </div>
                <div>
                    <img :src="getImageURL('purple-question-mark.svg')">
                </div>
            </div>
            
            <img :src="getImageURL('drinks.svg')" class="w-100">
        </div>
        
        <HoverMessage 
            v-if="showHoverMessage"
            image="alcohol-advice-image.png" 
            :text="langStore.lang.habits.webview.alcohol_hover_msg_text" 
            :title="langStore.lang.habits.webview.alcohol_hover_msg_title"/>
    </div>
</template>
<script setup>
import { useHabitsStore } from '../../../stores/habits';
import { useLangStore } from '../../../stores/lang';
import Title from '../Title.vue';
import HoverMessage from './HoverMessage.vue';
import { ref } from 'vue';
import { getImageURL } from '../../../utils/image_url_helper';
import { sanitizeNumberInput } from '../../../utils/sanitize_input';

const store = useHabitsStore();
const langStore = useLangStore();

const showHoverMessage = ref(false)

const handleInput = () => {
    const val = store.formData.alcohol_weekly_count

    if(Number(val) > 14){
        showHoverMessage.value = true
        return;
    }
    if(val !== null && val !== '' && val != 'e'){
        store.nextStep()
    }
}
</script>