import { defineStore } from "pinia/dist/pinia.cjs";
import { ref } from "vue";
import { createUpdateUrlParam } from '../utils/create_update_url_param';
import ApiService from "../services/api_service";

export const useHabitsStore = defineStore('habits', () => {
    const step = ref(1)

    const formData = ref({
        alcohol_weekly_count: null,
        anemia: null,
        cigarette_daily_count: null,
        drinking_alcohol: null,
        haemoglobin: null,
        haemoglobin_taken_at: null,
        iron_supplements: null,
        last_drank_at: null,
        last_smoked_at: null,
        smoking: null
    })

    const nextStep = () => {
        setTimeout(() => {
            if(step.value == 1 && formData.value.smoking === false) {
                step.value = 4
                return;
            }
    
            if(step.value == 4 && formData.value.drinking_alcohol === false) {
                step.value = 7
                return;
            }
    
            step.value++
        }, 500)
    }

    const prevStep = () => {
        if(step.value == 7 && formData.value.drinking_alcohol === false) {
            step.value = 4
            return;
        }

        if(step.value == 4 && formData.value.smoking === false) {
            step.value = 1
            return;
        }

        if (step.value == 1) {
            createUpdateUrlParam('exit', true)
            return;
        }
        
        step.value--
    }

    const save = (token) => {
        return new Promise((resolve, reject) => {
            ApiService.post('/p/habits', { ...formData.value, token: token }).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    return {
        step,
        formData,
        nextStep,
        prevStep,
        save
    }
})