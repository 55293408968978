<template>
    <div class="progress-bar-container">
        <div class="progress-bar" :style="{width: props.percent+'%' }"></div>
    </div>
</template>
<script setup>
const props = defineProps([
    'percent',
    'color',
    'activeColor'
])
</script>
<style scoped>
.progress-bar-container{
    height: 8px;
    width: 100%;
    border-radius: 12px;
    background: v-bind('props.color');
}

.progress-bar{
    height: 8px;
    border-radius: 12px;
    background: v-bind('props.activeColor')
}
</style>