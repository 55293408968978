<template>
    <div>
        <div class="mb-5">
            <Title :text="langStore.lang.mini_nutritional_assessment.webview.mobility_html"/>
        </div>

        <OptionsWithEmoji 
            v-model="store.formData.mobility"
            @selected="selected"
            :options="options" 
            bg-color="#ffffff"
            text-color="#1F2E99"
            active-bg="#019E94"
            active-text="#ffffff"
        />
    </div>
</template>
<script setup>
import { useMalnutritionStore } from '../../../stores/malnutrition';
import { useLangStore } from '../../../stores/lang';
import OptionsWithEmoji from '../../OptionsWithEmoji.vue';
import Title from '../Title.vue';

const store = useMalnutritionStore();
const langStore = useLangStore();

const props = defineProps(['options'])

const options = [
    {
        value: props.options.bed_or_chair_bound,
        text: langStore.lang.mini_nutritional_assessment.webview.mobility_options.bed_or_chair_bound,
        emoji: '😓'
    },
    {
        value: props.options.able_to_get_out_of_bed,
        text: langStore.lang.mini_nutritional_assessment.webview.mobility_options.able_to_get_out_of_bed,
        emoji: '😕'
    },
    {
        value: props.options.goes_out,
        text: langStore.lang.mini_nutritional_assessment.webview.mobility_options.goes_out,
        emoji: '🙂'
    },
]

const selected = val => {
    store.nextStep()
}
</script>