<template>
    <div>
        <div class="mb-5">
            <Title :text="langStore.lang.mini_nutritional_assessment.webview.cc" />
        </div>

        <Options 
            v-model="store.formData.cc"
            @selected="selected"
            :options="options" 
            bg-color="#ffffff"
            text-color="#1F2E99"
            active-bg="#019E94"
            active-text="#ffffff"
        />

        <div class="mac-cc-desc-container p-3 mb-3">
            <div class="d-flex justify-content-between align-items-start">
                <div>{{ langStore.lang.mini_nutritional_assessment.webview.cc_desc }}</div>
                <div>
                    <img :src="getImageURL('purple-question-mark.svg')">
                </div>
            </div>
            <div class="text-end mt-3">
                <img :src="getImageURL('mac-cc-background.svg')">
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMalnutritionStore } from '../../../stores/malnutrition';
import { useLangStore } from '../../../stores/lang';
import Options from '../../Options.vue';
import { getImageURL } from '../../../utils/image_url_helper';
import Title from '../Title.vue';

const store = useMalnutritionStore();
const langStore = useLangStore();

const props = defineProps(['options'])

const options = [
    {
        value: false,
        text: langStore.lang.mini_nutritional_assessment.webview.cc_options.less,
    },
    {
        value: true,
        text: langStore.lang.mini_nutritional_assessment.webview.cc_options.more,
    },
]

const selected = val => {
    store.nextStep()
}
</script>