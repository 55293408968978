<template>
    <div class="h-100 d-flex align-items-center justify-content-center">
        <div>
            <div class="agree-rectangle-container mb-4 mx-auto" @click="showAnimation">
                <div class="agree-rectangle d-flex align-items-center justify-content-center">
                    <div class="text-center">
                        <img :src="getImageURL('HandTap.svg')">
                        <div class="text-white fs-20px ff-monsterat">{{ langStore.lang.discharge_plan.webview.tap_to_agree }}</div>
                    </div>
                </div>
            </div>
            <div class="text-center text-dark-blue fs-24px lh-24px fw-bold ff-montserat">{{ langStore.lang.discharge_plan.webview.agreement_text }}</div>
        </div>

        <SuccessAnimation ref="success" :callback="() => store.step++" v-show="showSuccess"/>
    </div>
</template>
<script setup>
import { useLangStore } from '../../../stores/lang';
import { useDischargeConsentStore } from '../../../stores/discharge_consent';
import { getImageURL } from '../../../utils/image_url_helper';
import SuccessAnimation from '../../SuccessAnimation.vue';
import { ref } from 'vue';

const store = useDischargeConsentStore();
const langStore = useLangStore();

const success = ref(null)
const showSuccess = ref(false)

const showAnimation = () => {
    store.formData.agree_arrangements = true
    showSuccess.value = true
    success.value.animation.play()
}
</script>