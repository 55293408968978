<template>
    <div class="min-h-100vh d-flex" :class="blueBackground ? 'blue-bg' : 'webview-purple-blur-bg'" :style="{ paddingTop: paddingTop(), paddingBottom: paddingBottom() }">
        <div class="container px-16px flex-fill overflow-hidden position-relative">
            <div class="h-100 d-flex flex-column">
                <div class="mb-4 d-flex align-items-center justify-content-between">
                    <back-button :text="langStore.lang?.views?.back" @click="store.prevStep" v-if="store.step != 3"/>
                    <close-button class="ms-auto" @click="createUpdateUrlParam('exit', true)" />
                </div>

                <AddAllergy v-if="store.step == 1" />
                <AllergyDetails v-if="store.step == 2" />
                <Outro v-if="store.step == 3" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import { useAllergiesStore } from '../../../stores/allergies';
import { useLangStore } from '../../../stores/lang';
import BackButton from '../../BackButton.vue';
import CloseButton from '../../CloseButton.vue';

import { computed } from 'vue';
import AddAllergy from './AddAllergy.vue';
import AllergyDetails from './AllergyDetails.vue';
import Outro from './Outro.vue';
import { paddingTop, paddingBottom } from '../../../utils/webview_padding_helper';

const store = useAllergiesStore();
const langStore = useLangStore();

const props = defineProps([
    'allergies',
    'lang',
    'severityOptions',
])

if (props.allergies != '') {
    store.allergies = JSON.parse(props.allergies)
}

langStore.lang = JSON.parse(props.lang)
store.severityOptions = JSON.parse(props.severityOptions)

const blueBackground = computed(() => {
    return store.step == 3
})
</script>
<style scoped>
.blue-bg {
    background: #D3F2FF;
}
</style>