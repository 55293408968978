<template>
    <div>
        <div class="mb-4">
            <Title :text="langStore.lang.fasting.webview.carb_load"/>
        </div>
        <YesNoButtons 
            v-model="store.formData.carb_load"
            @selected="store.nextStep"
            bg-color="#ffffff"
            text-color="#1F2E99"
            active-bg="#D8CBFE"
            active-text="#1F2E99"
            :yes-label="langStore.lang.views.yes_label"
            :no-label="langStore.lang.views.no_label"
        />
        
    </div>
</template>
<script setup>
import { useFastingStore } from '../../../stores/fasting';
import { useLangStore } from '../../../stores/lang';
import YesNoButtons from '../../YesNoButtons.vue';
import Title from '../Title.vue';

const store = useFastingStore();
const langStore = useLangStore();
</script>