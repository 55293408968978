<template>
    <div class="h-100 d-flex flex-column pb-20px">
        <div class="mb-4">
            <Title :text="langStore.lang.habits.webview.haemoglobin"/>
        </div>
        <div class="mb-4">
            <div class="fs-18px text-gray11 mb-1 fw-300">{{ langStore.lang.views.type_number }}</div>
            <input type="number" class="form-control" v-model="store.formData.haemoglobin" @keydown="sanitizeNumberInput($event, 3)" @blur="handleInput">
        </div>

        <div class="mt-auto">
            <button class="btn btn-dark-blue-outline mobile w-100 h-56px input-border-radius" @click="store.nextStep">{{ langStore.lang.views.skip_if_unsure }}</button>
        </div>
        
        <HoverMessage 
            v-if="showHoverMessage"
            image="anemia-advice-image.png" 
            :text="langStore.lang.habits.webview.haemoglobin_hover_msg_text" 
            :title="langStore.lang.habits.webview.haemoglobin_hover_msg_title"/>
    </div>
</template>
<script setup>
import { useHabitsStore } from '../../../stores/habits';
import { useLangStore } from '../../../stores/lang';
import { sanitizeNumberInput } from '../../../utils/sanitize_input';
import Title from '../Title.vue';
import HoverMessage from './HoverMessage.vue';
import { ref } from 'vue';

const store = useHabitsStore();
const langStore = useLangStore();

const showHoverMessage = ref(false)

const handleInput = () => {
    const val = store.formData.haemoglobin

    if(Number(val) < 120){
        showHoverMessage.value = true
        return;
    }
    if(val !== null && val !== '' && val != 'e'){
        store.nextStep()
    }
}
</script>