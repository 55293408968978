<template>
    <div class="h-100vh d-flex webview-purple-blur-bg" :style="{ paddingTop: paddingTop(), paddingBottom: paddingBottom() }">
        <div class="container px-16px flex-fill overflow-hidden position-relative">
            <div class="h-100 d-flex flex-column">
                <div class="mb-4 d-flex align-items-center justify-content-between">
                    <back-button :text="langStore.lang?.views?.back" @click="store.prevStep" />
                    <close-button @click="createUpdateUrlParam('exit', true)" />
                </div>
                <div class="mb-3">
                    <Subtitle :text="subtitle" />
                </div>
                <div class="mb-5">
                    <ProgressBar :percent="percent" color="#F6F6F6" active-color="#7345FE" />
                </div>

                <div class="flex-fill">
                    <MedicalConditions v-if="store.step == 1" />
                    <FamilyConditions v-if="store.step == 2" />
                    <Diabetes v-if="store.step == 3" />
                    <BloodPressure v-if="store.step == 4" />
                    <BloodPressureMedication v-if="store.step == 5" />
                    <Outro v-if="store.step == 6" />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import { usePmhStore } from '../../../stores/pmh';
import { useLangStore } from '../../../stores/lang';
import BackButton from '../../BackButton.vue';
import CloseButton from '../../CloseButton.vue';
import Subtitle from '../Subtitle.vue';
import { computed } from 'vue';
import ProgressBar from '../../ProgressBar.vue';
import MedicalConditions from './MedicalConditions.vue';
import FamilyConditions from './FamilyConditions.vue';
import Diabetes from './Diabetes.vue';
import BloodPressure from './BloodPressure.vue';
import BloodPressureMedication from './BloodPressureMedication.vue';
import Outro from './Outro.vue';
import { paddingTop, paddingBottom } from '../../../utils/webview_padding_helper';

const langStore = useLangStore()
const store = usePmhStore()

const props = defineProps([
    'pmh',
    'lang',
])

if (props.pmh != 'null') {
    store.formData = JSON.parse(props.pmh)
}

langStore.lang = JSON.parse(props.lang)

const subtitle = computed(() => {
    const step = store.step == 5 ? 4 : store.step;
    return step + ' ' + langStore.lang.pmh.webview.of_4_questions;
})

const percent = computed(() => {
    const step = store.step == 5 ? 4 : store.step;
    return step / 4 * 100;
})
</script>