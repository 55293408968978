<template>
    <div class="h-100vh d-flex webview-purple-blur-bg" :style="{ paddingTop: paddingTop(), paddingBottom: paddingBottom() }">
        <div class="container px-16px flex-fill overflow-hidden position-relative">
            <div class="h-100 d-flex flex-column">
                <div class="mb-4 d-flex align-items-center justify-content-between">
                    <back-button :text="langStore.lang?.views?.back" @click="store.prevStep" />
                    <close-button @click="createUpdateUrlParam('exit', true)" />
                </div>
                <div class="mb-4">
                    <Subtitle :text="subtitle" />
                </div>
                <div class="mb-4">
                    <MultipartProgressBar :parts="3" :active="active" color="#b8a1ff" active-color="#7345FE" />
                </div>

                <div class="mb-4">
                    <AdviceCard :text="adviceText" :open-url="openUrl" />
                </div>

                <div class="flex-fill">
                    <Smoking v-if="store.step == 1" />
                    <WhenSmoked v-if="store.step == 2" />
                    <CigaretteCount v-if="store.step == 3" />
                    <Drinking v-if="store.step == 4" />
                    <WhenDrank v-if="store.step == 5" />
                    <AlcoholCount v-if="store.step == 6" />
                    <Anemia v-if="store.step == 7" />
                    <HaemoglobinValue v-if="store.step == 8" />
                    <HaemoglobinDate v-if="store.step == 9" />
                    <Outro v-if="store.step == 10" />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import { useHabitsStore } from '../../../stores/habits';
import { useLangStore } from '../../../stores/lang';
import BackButton from '../../BackButton.vue';
import CloseButton from '../../CloseButton.vue';
import Subtitle from '../Subtitle.vue';
import MultipartProgressBar from '../../MultipartProgressBar.vue';
import { computed } from 'vue';
import AdviceCard from './AdviceCard.vue';
import Smoking from './Smoking.vue';
import WhenSmoked from './WhenSmoked.vue';
import CigaretteCount from './CigaretteCount.vue';
import Drinking from './Drinking.vue';
import AlcoholCount from './AlcoholCount.vue';
import Anemia from './Anemia.vue';
import HaemoglobinValue from './HaemoglobinValue.vue';
import HaemoglobinDate from './HaemoglobinDate.vue';
import Outro from './Outro.vue';
import WhenDrank from './WhenDrank.vue';
import { paddingTop, paddingBottom } from '../../../utils/webview_padding_helper';

const langStore = useLangStore()
const store = useHabitsStore()

const props = defineProps([
    'habits',
    'lang',
])

if (props.habits != '') {
    store.formData = JSON.parse(props.habits)
}

langStore.lang = JSON.parse(props.lang)

const isFirstSection = computed(() => store.step < 4)
const isSecondSection = computed(() => store.step >= 4 && store.step < 7)
const isThirdSection = computed(() => store.step >= 7)

const active = computed(() => {
    let res;

    switch (true) {
        case isFirstSection.value:
            res = 1
            break;
        case isSecondSection.value:
            res = 2
            break;
        case isThirdSection.value:
            res = 3
            break;
    }

    return res;
})

const subtitle = computed(() => {
    let res;

    switch (true) {
        case isFirstSection.value:
            res = langStore.lang.habits.webview.smoking_title_html
            break;
        case isSecondSection.value:
            res = langStore.lang.habits.webview.alcohol_title_html
            break;
        case isThirdSection.value:
            res = langStore.lang.habits.webview.anemia_title_html
            break;
    }

    return res;
})

const openUrl = computed(() => {
    if (isFirstSection.value) {
        return 'https://www.cdc.gov/tobacco/data_statistics/fact_sheets/cessation/quitting/index.htm'
    }

    return null;
})

const adviceText = computed(() => {
    let res;

    switch (true) {
        case isFirstSection.value:
            res = langStore.lang.habits.webview.smoking_advice_html
            break;
        case isSecondSection.value:
            res = langStore.lang.habits.webview.alcohol_advice_html
            break;
        case isThirdSection.value:
            res = langStore.lang.habits.webview.anemia_advice_html
            break;
    }

    return res;
})
</script>