const extractPaddingFromURL = (key) => {
    const urlParams = new URLSearchParams(window.location.search);
    
    const padding = urlParams.get(key);

    if(!padding){
        return 0;
    }

    return padding+'px';
}

const paddingTop = () => {
    return extractPaddingFromURL('paddingTop');
}

const paddingBottom = () => {
    return extractPaddingFromURL('paddingBottom');
}

export {
    paddingTop,
    paddingBottom
}