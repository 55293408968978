<template>
    <div class="d-flex text-decoration-none cursor-pointer">
        <img :src="arrow">
        <div class="fs-20px text-dark-blue ms-2">{{ text }}</div>
    </div>
</template>
<script setup>
import arrow from '@images/arrow-narrow-left.svg'
import { useLangStore } from '../stores/lang';
import { computed } from 'vue';

const props = defineProps(['text'])

const langStore = useLangStore();

const text = computed(() => {
    return props.text ?? langStore.lang.back
})
</script>