<template>
    <div class="h-100vh d-flex" :class="backgroundClass" :style="{ paddingTop: paddingTop(), paddingBottom: paddingBottom() }">
        <div class="container px-16px flex-fill">
            <div class="h-100 d-flex flex-column">
                <div class="mb-4 d-flex align-items-center justify-content-between">
                    <back-button :text="langStore.lang?.views?.back" @click="store.prevStep" />
                    <close-button @click="createUpdateUrlParam('exit', true)" />
                </div>
                <div class="mb-4">
                    <Subtitle :text="subtitleText" />
                </div>
                <div class="mb-4">
                    <MultipartProgressBar :parts="3" :active="active" color="#7fcdc9" active-color="#019E94" />
                </div>

                <div class="mb-5 text-center">
                    <img :src="getImageURL(image)">
                </div>

                <Food :options="declinedFoodOptions" v-if="store.step == 1" />
                <Weight :options="weightLossOptions" v-if="store.step == 2" />
                <Mobility :options="mobilityOptions" v-if="store.step == 3" />
                <PsychologicalStress v-if="store.step == 4" />
                <NeurologicalProblems :options="neuropsychologicalOptions" v-if="store.step == 5" />
                <LivesIndependently v-if="store.step == 6" />
                <Drugs v-if="store.step == 7" />
                <PressureSores v-if="store.step == 8" />
                <HealthStatus :options="healthStatusOptions" v-if="store.step == 9" />
                <Mac :options="macOptions" v-if="store.step == 10" />
                <Cc v-if="store.step == 11" />
                <Meals :options="mealOptions" v-if="store.step == 12" />
                <DairyProducts v-if="store.step == 13" />
                <Eggs v-if="store.step == 14" />
                <Meat v-if="store.step == 15" />
                <Vegetables v-if="store.step == 16" />
                <Fluid :options="fluidOptions" v-if="store.step == 17" />
                <Feeding :options="feedingOptions" v-if="store.step == 18" />
                <SelfView :options="selfViewOptions" v-if="store.step == 19" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMalnutritionStore } from '../../../stores/malnutrition';
import { useLangStore } from '../../../stores/lang';
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import BackButton from '../../BackButton.vue';
import CloseButton from '../../CloseButton.vue';
import { computed } from 'vue';
import Subtitle from '../Subtitle.vue';
import MultipartProgressBar from '../../MultipartProgressBar.vue';
import { getImageURL } from '../../../utils/image_url_helper';
import Food from './Food.vue';
import Weight from './Weight.vue';
import Mobility from './Mobility.vue';
import PsychologicalStress from './PsychologicalStress.vue';
import NeurologicalProblems from './NeurologicalProblems.vue';
import LivesIndependently from './LivesIndependently.vue';
import Drugs from './Drugs.vue';
import PressureSores from './PressureSores.vue';
import HealthStatus from './HealthStatus.vue';
import Mac from './Mac.vue';
import Cc from './Cc.vue';
import Meals from './Meals.vue';
import DairyProducts from './DairyProducts.vue';
import Eggs from './Eggs.vue';
import Meat from './Meat.vue';
import Vegetables from './Vegetables.vue';
import Fluid from './Fluid.vue';
import Feeding from './Feeding.vue';
import SelfView from './SelfView.vue';
import { paddingTop, paddingBottom } from '../../../utils/webview_padding_helper';

const store = useMalnutritionStore();
const langStore = useLangStore();

const props = defineProps([
    'malnutrition',
    'lang',
    'declinedFoodOptions',
    'weightLossOptions',
    'mobilityOptions',
    'neuropsychologicalOptions',
    'healthStatusOptions',
    'macOptions',
    'mealOptions',
    'feedingOptions',
    'selfViewOptions',
    'fluidOptions'
])

if (props.malnutrition != '') {
    store.formData = JSON.parse(props.malnutrition)
}

const declinedFoodOptions = JSON.parse(props.declinedFoodOptions)
const weightLossOptions = JSON.parse(props.weightLossOptions)
const mobilityOptions = JSON.parse(props.mobilityOptions)
const neuropsychologicalOptions = JSON.parse(props.neuropsychologicalOptions)
const healthStatusOptions = JSON.parse(props.healthStatusOptions)
const macOptions = JSON.parse(props.macOptions)
const mealOptions = JSON.parse(props.mealOptions)
const feedingOptions = JSON.parse(props.feedingOptions)
const selfViewOptions = JSON.parse(props.selfViewOptions)
const fluidOptions = JSON.parse(props.fluidOptions)

langStore.lang = JSON.parse(props.lang)

const isThirdSection = computed(() => store.step > 11)
const isSecondSection = computed(() => store.step > 5 && store.step <= 11)
const isFirstSection = computed(() => store.step <= 5)

const backgroundClass = computed(() => {
    if (isThirdSection.value) {
        return 'webview-green-blur-bg'
    }
    if (isSecondSection.value) {
        return 'webview-blue-blur-bg'
    }
    return 'webview-purple-blur-bg'
})

const subtitleText = computed(() => {
    let text;

    switch (true) {
        case isFirstSection.value:
            text = langStore.lang.mini_nutritional_assessment.webview.first_section_subtitle_html
            break;
        case isSecondSection.value:
            text = langStore.lang.mini_nutritional_assessment.webview.second_section_subtitle_html
            break;
        case isThirdSection.value:
            text = langStore.lang.mini_nutritional_assessment.webview.third_section_subtitle_html
            break;
    }

    return text;
})

const image = computed(() => {
    let src;

    switch (true) {
        case isFirstSection.value:
            src = 'malnutrition-section-1-img.svg'
            break;
        case isSecondSection.value:
            src = 'malnutrition-section-2-img.svg'
            break;
        case isThirdSection.value:
            src = 'malnutrition-section-3-img.svg'
            break;
    }

    return src;
})

const active = computed(() => {
    let res;

    switch (true) {
        case isFirstSection.value:
            res = 1
            break;
        case isSecondSection.value:
            res = 2
            break;
        case isThirdSection.value:
            res = 3
            break;
    }

    return res;
})
</script>