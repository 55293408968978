<template>
    <div>
        <div class="mb-5">
            <Title :text="langStore.lang.mini_nutritional_assessment.webview.meals_html"/>
        </div>

        <div class="d-flex justify-content-center">
            <div class="meal-option d-flex align-items-center justify-content-center" :class="{ 'active' : store.formData.full_meals_count == 1 }" @click="select(1)">
                <div>
                    <div class="fs-37px ff-montserat text-green fw-600 text-center">1</div>
                    <div class="text-dark-blue fs-16px ff-montserat">{{ langStore.lang.mini_nutritional_assessment.webview.meal }}</div>
                </div>
            </div>
            <div class="meal-option d-flex align-items-center justify-content-center mx-3" :class="{ 'active' : store.formData.full_meals_count == 2 }" @click="select(2)">
                <div>
                    <div class="fs-37px ff-montserat text-green fw-600 text-center">2</div>
                    <div class="text-dark-blue fs-16px ff-montserat">{{ langStore.lang.mini_nutritional_assessment.webview.meals }}</div>
                </div>
            </div>
            <div class="meal-option d-flex align-items-center justify-content-center" :class="{ 'active' : store.formData.full_meals_count == 3 }" @click="select(3)">
                <div>
                    <div class="fs-37px ff-montserat text-green fw-600 text-center">3</div>
                    <div class="text-dark-blue fs-16px ff-montserat">{{ langStore.lang.mini_nutritional_assessment.webview.meals }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useMalnutritionStore } from '../../../stores/malnutrition';
import { useLangStore } from '../../../stores/lang';
import Title from '../Title.vue';

const store = useMalnutritionStore();
const langStore = useLangStore();

const props = defineProps(['options'])

const select = val => {
    store.formData.full_meals_count = val
    store.nextStep()
}
</script>