<template>
    <div class="h-100 d-flex flex-column">
        <div class="mb-4 text-dark-blue fs-24px lh-24px ff-montserat fw-600" v-html="langStore.lang.surgery_preparation.webview.joint_replacement.title_html"></div>
        <div class="text-dark-blue fs-18px ff-montserat mb-4" v-html="langStore.lang.surgery_preparation.webview.joint_replacement.text_html"></div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="store.nextStep">{{ langStore.lang.views.continue }}</button>
        </div>
    </div>
</template>
<script setup>
import { useLangStore } from '../../../stores/lang';
import { useSurgeryPreparationStore } from '../../../stores/surgery_preparation';

const langStore = useLangStore();
const store = useSurgeryPreparationStore();
</script>