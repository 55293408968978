<template>
    <div class="d-flex flex-column h-100">
        <div class="mb-4">
            <Title :text="langStore.lang.pmh.webview.current_or_past_conditions" />
        </div>

        <div class="mb-3">
            <YesNoButtons 
                v-model="store.formData.current_or_past_conditions"
                bg-color="#ffffff"
                text-color="#1F2E99"
                active-bg="#D8CBFE"
                active-text="#1F2E99"
                :yes-label="langStore.lang.views.yes_label"
                :no-label="langStore.lang.views.no_label"
            />
        </div>

        <div>
            <textarea class="form-control" v-model="store.formData.conditions_desc" rows="3" :placeholder="langStore.lang.views.write_your_comments"></textarea>
        </div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="store.nextStep" :disabled="store.formData.current_or_past_conditions == null">{{ langStore.lang.views.continue }}</button>
        </div>
    </div>
</template>
<script setup>
import { useLangStore } from '../../../stores/lang';
import { usePmhStore } from '../../../stores/pmh';
import YesNoButtons from '../../YesNoButtons.vue';
import Title from '../Title.vue';

const store = usePmhStore();
const langStore = useLangStore();
</script>