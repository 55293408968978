<template>
    <div>
        <div class="mb-5">
            <Title :text="langStore.lang.mini_nutritional_assessment.webview.meat_html"/>
        </div>

        <div class="d-flex justify-content-center">
            <YesNoWithThumbs 
                v-model="store.formData.meat_daily"
                @selected="selected"
                bg-color="#ffffff"
                text-color="#1F2E99"
                active-bg="#019E94"
                active-text="#ffffff"
                :yes-label="langStore.lang.views.yes_label"
                :no-label="langStore.lang.views.no_label"
            />
        </div>
    </div>
</template>
<script setup>
import { useMalnutritionStore } from '../../../stores/malnutrition';
import { useLangStore } from '../../../stores/lang';
import YesNoWithThumbs from '../../YesNoWithThumbs.vue';
import Title from '../Title.vue';

const store = useMalnutritionStore();
const langStore = useLangStore();


const selected = val => {
    store.nextStep()
}
</script>