<template>
    <div class="position-fixed w-100 h-100 top-0 left-0 d-flex flex-column justify-content-end align-items-center blur-bg">
        <a href="javascript:;" class="mb-3" @click="store.nextStep">
            <img :src="getImageURL('close-habits-hover.svg')">
        </a>
        <div class="white-area d-flex justify-content-center align-items-center">
            <div class="text-center w-100vw px-4">
                <img :src="getImageURL(props.image)" class="mb-4">
                <div class="fs-18px text-dark-blue ff-montserat mb-4 lh-21px">{{ props.text }}</div>
                <div class="text-primary fs-29px fw-bold lh-29px">{{ props.title }}</div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useHabitsStore } from '../../../stores/habits';
import { getImageURL } from '../../../utils/image_url_helper';

const store = useHabitsStore()

const props = defineProps([ 
    'title',
    'text',
    'image'
])
</script>
<style scoped>
.blur-bg{
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(2.7300000190734863px);
}

.white-area{
    height:65%;
    background: white;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    width: 250%;
}

.w-100vw{
    width: 100vw;
}
</style>