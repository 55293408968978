<template>
    <div>
        <div class="mb-3 webview-option d-flex align-items-center" :class="{'active' : active == option.value}" :style="{ background: props.bgColor, color: props.textColor }" v-for="option in props.options" @click="selected(option.value)">
            <div>{{ option.text }}</div>
        </div>
    </div>
</template>
<script setup>
    import { ref } from 'vue';
    const props = defineProps([
        'modelValue',
        'options',
        'bgColor',
        'textColor',
        'activeBg',
        'activeText'
    ])

    const emit = defineEmits(['selected', 'update:modelValue'])

    const active = ref(props.modelValue)

    const selected = value => {
        active.value = value
        emit('update:modelValue', value)
        emit('selected', value)
    }
</script>
<style scoped>
    .webview-option.active, .webview-option:active{
        background: v-bind('props.activeBg')!important;
        color: v-bind('props.activeText')!important;
    }
</style>