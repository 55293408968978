import { createApp } from 'vue/dist/vue.esm-bundler';
import { createPinia } from "pinia/dist/pinia.cjs";
import ApiService from '../services/api_service';
import vSelect from 'vue-select'

import PdqQuestionnaire from '../components/pdq/PdqQuestionnaire.vue';
import CreatePatient from '../components/patient/CreatePatient.vue';
import CarePlan from '../components/patient/CarePlan.vue';
import EditPhysioPlanModal from '../components/physio/EditPhysioPlanModal.vue';
import MalnutritionQuestionnaire from '../components/eras/malnutrition/MalnutritionQuestionnaire.vue';
import DischargeConsentQuestionnaire from '../components/eras/discharge-consent/DischargeConsentQuestionnaire.vue';
import HabitsQuestionnaire from '../components/eras/habits/HabitsQuestionnaire.vue';
import MedicationsQuestionnaire from '../components/eras/medication/MedicationsQuestionnaire.vue';
import AllergiesQuestionnaire from '../components/eras/allergy/AllergiesQuestionnaire.vue';
import PmhQuestionnaire from '../components/eras/pmh/PmhQuestionnaire.vue';
import FastingQuestionnaire from '../components/eras/fasting/FastingQuestionnaire.vue';
import PreSurgeryChecklist from '../components/eras/pre-surgery-checklist/PreSurgeryChecklist.vue';
import SurgeryPreparation from '../components/eras/surgery_preparation/SurgeryPreparation.vue';

export const initVueApp = () => {
    const app = createApp()
    ApiService.init(app)

    const pinia = createPinia();
    app.use(pinia)

    app.component('v-select', vSelect)
    app.component('PdqQuestionnaire', PdqQuestionnaire)
    app.component('CreatePatient', CreatePatient)
    app.component('CarePlan', CarePlan)
    app.component('EditPhysioPlanModal', EditPhysioPlanModal)
    app.component('MalnutritionQuestionnaire', MalnutritionQuestionnaire)
    app.component('DischargeConsentQuestionnaire', DischargeConsentQuestionnaire)
    app.component('HabitsQuestionnaire', HabitsQuestionnaire)
    app.component('MedicationsQuestionnaire', MedicationsQuestionnaire)
    app.component('AllergiesQuestionnaire', AllergiesQuestionnaire)
    app.component('PmhQuestionnaire', PmhQuestionnaire)
    app.component('FastingQuestionnaire', FastingQuestionnaire)
    app.component('PreSurgeryChecklist', PreSurgeryChecklist)
    app.component('SurgeryPreparation', SurgeryPreparation)

    app.directive('click-outside', {
        beforeMount(el, binding) {
            el.clickOutsideEvent = function(event) {
                // Check if the clicked element is neither the element
                // to which the directive is applied nor its child
                if (!(el === event.target || el.contains(event.target))) {
                    // Invoke the provided method
                    binding.value(event);
                }
            };
            document.addEventListener('click', el.clickOutsideEvent);
        },
        unmounted(el) {
            // Remove the event listener when the bound element is unmounted
            document.removeEventListener('click', el.clickOutsideEvent);
        },
      });

    return app;
}