<template>
    <div class="h-100 d-flex flex-column">
        <div class="fs-32px lh-32px text-primary fw-500 ff-montserat mb-3">{{ langStore.lang.pre_surgery_checklist.webview.add_task }}</div>

        <div>
            <textarea v-model="store.formData.name" class="form-control" rows="3" :placeholder="langStore.lang.pre_surgery_checklist.webview.add_task_placeholder"></textarea>
        </div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="submit" :disabled="!store.formData.name">{{ langStore.lang.pre_surgery_checklist.webview.save_task }}</button>
        </div>
    </div>
</template>
<script setup>
import { usePreSurgeryChecklistStore } from '../../../stores/pre_surgery_checklist';
import { useLangStore } from '../../../stores/lang';

const store = usePreSurgeryChecklistStore();
const langStore = useLangStore();

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

const submit = () => {
    store.save(token).then(() => {
        store.prevStep()
        store.formData.name = null
        store.formData.completed = null
    })
}
</script>