<template>
    <div class="d-flex justify-content-between">
        <button class="btn btn-white text-center px-4 mb-3 yes-no-item ff-motserat me-1" :class="{'active' : active}" :style="{ background: props.bgColor, color: props.textColor }" @click="selected(true)">{{ props.yesLabel }}</button>
        <button class="btn btn-white text-center px-4 mb-3 yes-no-item ff-motserat ms-1" :class="{'active' : active === false}" :style="{ background: props.bgColor, color: props.textColor }" @click="selected(false)">{{ props.noLabel }}</button>
    </div>
</template>
<script setup>
    import { ref } from 'vue';
    const props = defineProps([
        'modelValue',
        'bgColor',
        'textColor',
        'activeBg',
        'activeText',
        'yesLabel',
        'noLabel'
    ])

    const emit = defineEmits(['selected', 'update:modelValue'])

    const active = ref(props.modelValue)

    const selected = value => {
        active.value = value
        emit('update:modelValue', value)
        emit('selected', value)
    }
</script>
<style scoped>
    .yes-no-item{
        min-width: 165px;
        border-radius: 30px !important;
        box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.04);
        font-size: 16px;
        min-height: 56px;
    }

    .yes-no-item.active, .yes-no-item:active{
        background: v-bind('props.activeBg')!important;
    }

    .yes-no-item.active .text, .yes-no-item:active .text{
        color: v-bind('props.activeText')!important;
    }
</style>