<template>
    <div class="h-100 d-flex flex-column">
        <div class="mb-4 text-dark-blue fs-24px lh-24px ff-montserat fw-600" v-html="langStore.lang.surgery_preparation.webview.piercings.title_html"></div>
        <div class="text-dark-blue fs-18px ff-montserat mb-4" v-html="langStore.lang.surgery_preparation.webview.piercings.text_html"></div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="createUpdateUrlParam('exit', true)">{{ langStore.lang.surgery_preparation.webview.back_to_surgery_prep }}</button>
        </div>
    </div>
</template>
<script setup>
import { useLangStore } from '../../../stores/lang';
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';

const langStore = useLangStore();
</script>