const sanitizeNumberInput = (event, length = 3) => {
    if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57)
    {
        event.preventDefault();
    }

    event.target.value = event.target.value.slice(0, (length - 1));
}

export {
    sanitizeNumberInput
}