<template>
    <div class="min-h-100vh d-flex" :class="store.step == 1 ? 'webview-orange-blur-bg' : 'webview-purple-blur-bg'" :style="{ paddingTop: paddingTop(), paddingBottom: paddingBottom() }">
        <div class="container px-16px flex-fill overflow-hidden position-relative">
            <div class="h-100 d-flex flex-column">
                <div class="mb-4 d-flex align-items-center justify-content-between">
                    <back-button :text="langStore.lang?.views?.back" @click="store.prevStep" />
                    <close-button @click="createUpdateUrlParam('exit', true)" />
                </div>

                <div class="mb-4">
                    <MultiPartProgressBar :parts="5" :active="store.step" color="#b8a1ff" active-color="#7345FE" />
                </div>

                <div class="mb-4">
                    <lottie-player :key="store.step" autoplay loop
                        :src="getImageURL('surgery-prep-screen' + store.step + '.json')"></lottie-player>
                </div>

                <div class="flex-fill">
                    <Cancellation v-if="store.step == 1" />
                    <EssentialSteps v-if="store.step == 2" />
                    <JointReplacement v-if="store.step == 3" />
                    <DentalInfo v-if="store.step == 4" />
                    <Piercings v-if="store.step == 5" />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import { useSurgeryPreparationStore } from '../../../stores/surgery_preparation';
import { useLangStore } from '../../../stores/lang';
import BackButton from '../../BackButton.vue';
import CloseButton from '../../CloseButton.vue';
import Cancellation from './Cancellation.vue';
import MultiPartProgressBar from '../../MultipartProgressBar.vue'
import { getImageURL } from '../../../utils/image_url_helper';
import EssentialSteps from './EssentialSteps.vue';
import JointReplacement from './JointReplacement.vue';
import DentalInfo from './DentalInfo.vue';
import Piercings from './Piercings.vue';
import { paddingTop, paddingBottom } from '../../../utils/webview_padding_helper';

const store = useSurgeryPreparationStore();
const langStore = useLangStore();

const props = defineProps([
    'lang',
])

langStore.lang = JSON.parse(props.lang)

</script>