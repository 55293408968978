<template>
    <div>
        <div class="mb-4">
            <Title :text="langStore.lang.habits.webview.anemia"/>
        </div>
        <div class="mb-4">
            <YesNoButtons 
                v-model="store.formData.anemia"
                @selected="handleSelection"
                bg-color="#ffffff"
                text-color="#1F2E99"
                active-bg="#D8CBFE"
                active-text="#1F2E99"
                :yes-label="langStore.lang.views.yes_label"
                :no-label="langStore.lang.views.no_label"
            />
        </div>
        <div class="mb-4">
            <Title :text="langStore.lang.habits.webview.iron_supplements"/>
        </div>
        <div class="mb-4">
            <YesNoButtons 
                v-model="store.formData.iron_supplements"
                @selected="handleSelection"
                bg-color="#ffffff"
                text-color="#1F2E99"
                active-bg="#D8CBFE"
                active-text="#1F2E99"
                :yes-label="langStore.lang.views.yes_label"
                :no-label="langStore.lang.views.no_label"
            />
        </div>
    </div>
</template>
<script setup>
import { watch } from 'vue';
import { useHabitsStore } from '../../../stores/habits';
import { useLangStore } from '../../../stores/lang';
import YesNoButtons from '../../YesNoButtons.vue';
import Title from '../Title.vue';

const store = useHabitsStore();
const langStore = useLangStore();

const handleSelection = () => {
    if(store.formData.anemia !== null && store.formData.iron_supplements !== null) {
        store.nextStep()
    }
}
</script>