import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-buttons"
export default class extends Controller {

  static values = {
    multiple: {
      type: Boolean,
      default: false
    }
  }

  connect() {
  }

  toggleButton(e){
    const el = e.currentTarget
    const checkbox = el.querySelector('input[type="checkbox"]')

    if(!this.multipleValue){
      this.uncheckAllButtons();
      checkbox.checked = true
    }else{
      if(e.target.tagName != 'INPUT'){
        checkbox.checked = !checkbox.checked
      }
    }

    window.dispatchEvent(new Event('checkbox-button-changed'))
  }

  uncheckAllButtons(){
    const inputs = this.element.querySelectorAll('input[type="checkbox"]');

    inputs.forEach(input => input.checked = false)
  }
}
