<template>
    <div class="h-100vh d-flex webview-purple-blur-bg" :style="{ paddingTop: paddingTop(), paddingBottom: paddingBottom() }">
        <div class="container px-16px flex-fill overflow-hidden position-relative">
            <div class="h-100 d-flex flex-column">
                <div class="mb-4 d-flex align-items-center justify-content-between">
                    <back-button :text="langStore.lang?.views?.back" @click="store.prevStep" />
                    <close-button @click="createUpdateUrlParam('exit', true)" />
                </div>
                <div class="mb-4">
                    <Subtitle :text="langStore.lang.fasting.webview.monitoring_fasting_routine" />
                </div>
                <div class="mb-4">
                    <MultipartProgressBar :parts="3" :active="store.step" color="#b8a1ff" active-color="#7345FE" />
                </div>

                <div class="mb-4">
                    <AdviceCard :text="langStore.lang.fasting.webview.advice" />
                </div>

                <div class="flex-fill">
                    <Fluid v-if="store.step == 1" />
                    <Food v-if="store.step == 2" />
                    <Carbs v-if="store.step == 3" />
                    <Outro v-if="store.step == 4" />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import { useFastingStore } from '../../../stores/fasting';
import { useLangStore } from '../../../stores/lang';
import BackButton from '../../BackButton.vue';
import CloseButton from '../../CloseButton.vue';
import Subtitle from '../Subtitle.vue';
import MultipartProgressBar from '../../MultipartProgressBar.vue';
import AdviceCard from './AdviceCard.vue';
import Fluid from './Fluid.vue';
import Food from './Food.vue';
import Carbs from './Carbs.vue';
import Outro from './Outro.vue';
import { paddingTop, paddingBottom } from '../../../utils/webview_padding_helper';

const langStore = useLangStore()
const store = useFastingStore()

const props = defineProps([
    'fasting',
    'lang',
])

const extractTime = dateTime => {
    return dateTime.split('T')[1].split('.')[0]
}

if (props.fasting != '') {
    store.formData = JSON.parse(props.fasting)
    store.formData.fluid_time = extractTime(store.formData.fluid_time)
    store.formData.food_time = extractTime(store.formData.food_time)
}

langStore.lang = JSON.parse(props.lang)
</script>