<template>
    <div>
        <div class="mb-4">
            <Title :text="langStore.lang.habits.webview.smoking"/>
        </div>
        <YesNoButtons 
            v-model="store.formData.smoking"
            @selected="store.nextStep"
            bg-color="#ffffff"
            text-color="#1F2E99"
            active-bg="#D8CBFE"
            active-text="#1F2E99"
            :yes-label="langStore.lang.views.yes_label"
            :no-label="langStore.lang.views.no_label"
        />
        
    </div>
</template>
<script setup>
import { useHabitsStore } from '../../../stores/habits';
import { useLangStore } from '../../../stores/lang';
import ButtonWithRadio from '../../ButtonWithRadio.vue';
import YesNoButtons from '../../YesNoButtons.vue';
import Title from '../Title.vue';

const store = useHabitsStore();
const langStore = useLangStore();
</script>