import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="success-animation"
export default class extends Controller {
  static values = {
    redirect: String
  }
  
  static targets = ['player']

  connect() {
    this.playerTarget.addEventListener('complete', () => {
      document.location.href = this.redirectValue
    })
  }
}
