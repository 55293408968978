import { defineStore } from "pinia/dist/pinia.cjs";
import { ref } from "vue";
import { createUpdateUrlParam } from '../utils/create_update_url_param';
import ApiService from "../services/api_service";

export const useDischargeConsentStore = defineStore('discharge-consent', () => {
    const step = ref(1)

    const formData = ref({
        after_surgery: null,
        agree_arrangements: null,
        community_support: null,
        who_i_am: null
    })

    const nextStep = () => {
        step.value++
    }

    const prevStep = () => {
        if (step.value == 1) {
            createUpdateUrlParam('exit', true)
            return;
        }
        
        step.value--
    }

    const save = (token) => {
        return new Promise((resolve, reject) => {
            ApiService.post('/p/discharge_consent', { ...formData.value, token: token }).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    return {
        step,
        formData,
        nextStep,
        prevStep,
        save
    }
})