<template>
    <div class="care-plan-table" v-if="patientStore.formData.activities">
        <div class="d-md-flex justify-content-between align-items-center px-3 py-4">
            <div class="d-flex mb-3 mb-md-0" v-if="props.enableEditButtons">
                <div class="fs-18px text-dark-blue fw-bold">{{ langStore.lang.views.schedule }}</div>
                <div class="d-flex" v-if="!linkformMode">
                    <button class="btn btn-sm btn-orange px-3 ms-3" @click="editMode = true" v-if="!editMode">{{
                        langStore.lang.views.edit }}</button>
                    <button class="btn btn-sm btn-orange px-3 ms-3" @click="saveChanges" ref="saveButton" v-if="editMode">{{
                        langStore.lang.views.save_changes }}</button>
                    <button class="btn btn-sm btn-orange-outline px-3 ms-3" @click="getCarePlanActivities();editMode = false;" v-if="editMode">{{
                        langStore.lang.views.cancel }}</button>
                </div>
                <div v-if="!editMode">
                    <button class="btn btn-sm btn-primary px-3 ms-3" @click="getLinkformActivities"
                        v-if="!linkformMode">{{ langStore.lang.views.show_linkform_data }}</button>
                    <button class="btn btn-sm btn-primary px-3 ms-3" @click="getCarePlanActivities" v-else>{{
                        langStore.lang.views.show_careplan_data }}</button>
                </div>
            </div>
            <slot name="header" v-else></slot>
            <div class="d-flex ms-md-3 text-dark-blue">
                <img :src="calendarIcon">
                <div class="mx-3 text-nowrap">{{ langStore.lang.views.week }} {{ weekRangeText }}</div>
                <a href="javascript:;" class="me-1" @click="prev">
                    <img :src="prevWeek">
                </a>
                <a href="javascript:;">
                    <img :src="nextWeek" @click="next">
                </a>
            </div>
        </div>
        <div class="d-flex">
            <div>
                <div>
                    <div class="bg-white text-nowrap border-top py-2 px-3 fs-13px text-dark-blue border-top">
                        <div class="h-30px d-flex align-items-center">{{ langStore.lang.views.recovery_day }}</div>
                    </div>
                </div>
                <div v-for="(item, key) in patientStore.formData.activities.templates" :key="key">
                    <div class="text-nowrap h-50px d-flex align-items-center border-top fs-13px px-3"
                        :class="key % 2 == 0 ? 'even' : 'odd'">{{ item[0] }}</div>
                </div>
            </div>
            <div class="flex-fill">
                <div>
                    <div class="d-flex bg-white border-top">
                        <div class="care-plan-cell py-2 text-center cell fs-13px border-start text-dark-blue d-block"
                            :class="{ 'today': day == props.day }" v-for="day in days">
                            <div>{{ day }}</div>
                        </div>
                    </div>
                    <div v-for="(item, key) in patientStore.formData.activities.templates" :key="key">
                        <div class="d-flex">
                            <div class="h-50px d-flex align-items-center fs-13px border-top border-start justify-content-center care-plan-cell cell"
                                :class="key % 2 == 0 ? 'even' : 'odd'" v-for="day in days">
                                <div class="w-100 h-100">
                                    <div class="h-100 d-flex align-items-center justify-content-center cp-past-activity"
                                        :class="{ 'grayed': editMode }"
                                        v-if="(day < props.day && patientStore.formData.activities.schedule[item[1]][day].assigned) || patientStore.formData.activities.schedule[item[1]][day].completed">
                                        <a href="javascript:;"
                                            :data-url="patientStore.formData.activities.schedule[item[1]][day].url"
                                            data-action="click->pdf#showPdfModal"
                                            v-if="patientStore.formData.activities.schedule[item[1]][day].completed">
                                            <img :src="completedIcon">
                                        </a>
                                        <img :src="failedIcon" v-else>
                                    </div>
                                    <a href="javascript:;"
                                        class="d-block h-100 d-flex align-items-center justify-content-center"
                                        @click="selectDay(patientStore.formData.activities.schedule, item[1], day)"
                                        v-else>
                                        <img :src="pendingIcon"
                                            v-if="patientStore.formData.activities.schedule[item[1]][day].assigned">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { usePatientStore } from '../../stores/patient';
import { useLangStore } from '../../stores/lang';
import prevWeek from '@images/prev-week.svg'
import nextWeek from '@images/next-week.svg'
import completedIcon from '@images/completed-assignment.svg'
import failedIcon from '@images/failed-assignment.svg'
import pendingIcon from '@images/pending-assignment.svg'
import calendarIcon from '@images/blue-calendar.svg'
import { Modal } from 'bootstrap';
import { computed } from 'vue';
import { disableLoading, setLoading } from '../../utils/button_helper';

const screenBreakpoint = 992

const patientStore = usePatientStore();
const langStore = useLangStore();

const props = defineProps(['activities', 'week', 'day', 'patientId', 'clinicalTeamId', 'operationType', 'editMode', 'lang', 'enableEditButtons'])

langStore.lang = props.lang

const editMode = ref(props.editMode)
const linkformMode = ref(false)
let initialActivities;

const setupActivities = (activities) => {
    patientStore.formData.activities = activities
    initialActivities = JSON.parse(JSON.stringify(activities))
}

setupActivities(props.activities)

if (!patientStore.formData.activities) {
    patientStore.getCarePlanTemplate(props.clinicalTeamId, props.operationType).then(data => {
        patientStore.formData.activities = data
    })
}

const startWeek = ref(Math.max(props.week, 1))
const endWeek = ref(startWeek.value + 1)

const days = ref([]);

const init = () => {
    const isSmallWindow = window.innerWidth < screenBreakpoint;
    days.value = []
    const start = startWeek.value * 7 - 7
    let end = isSmallWindow ? start + 7 : start + 14
    end = Math.min(end, 365)

    for (let i = start; i <= end; i++) {
        days.value.push(i)
    }

    endWeek.value = isSmallWindow ? startWeek.value : startWeek.value + 1;
}

init();

window.addEventListener('resize', () => init())

const next = () => {
    const lastWeek = window.innerWidth < screenBreakpoint ? 53 : 52;
    if (startWeek.value < lastWeek) {
        startWeek.value++;
        init()
    }
}

const prev = () => {
    if (startWeek.value > 1) {
        startWeek.value--;
        init()
    }
}

const selectDay = (activities, activityName, day) => {
    if (day < props.day) return;
    if (!editMode.value) return;

    const activity = activities[activityName][day]
    activity.assigned = !activity.assigned
}

const saveButton = ref(null)

const saveChanges = async () => {
    
    setLoading(saveButton.value, true)

    const data = {
      add: {},
      remove: {}
    }
    
    patientStore.formData.activities.templates.forEach(template => {
        template = template[1]
        const initialDays = []
        const currentDays = []

        for(const [day, value] of Object.entries(initialActivities.schedule[template])){
            if(value.assigned) initialDays.push(Number(day))
        }

        for(const [day, value] of Object.entries(patientStore.formData.activities.schedule[template])){
            if(value.assigned) currentDays.push(Number(day))
        }

        const removedValues = initialDays.filter(item => !currentDays.includes(item))
        const addedValues = currentDays.filter(item => !initialDays.includes(item))

        if(addedValues.length > 0){
            data.add[template] = addedValues
        }
        if(removedValues.length > 0){
            data.remove[template] = removedValues
        }
    })

    await patientStore.updateCarePlan(props.patientId, data).then(res => {
        setupActivities(res)
        editMode.value = false
        const el = document.getElementById('update-care-plan-success-modal')
        const modal = new Modal(el)
        modal.show()
    }).catch(() => {})

    disableLoading(saveButton.value, true)
}

const getLinkformActivities = () => {
    patientStore.getLinkformActivities(props.patientId)
    linkformMode.value = true
}

const getCarePlanActivities = () => {
    patientStore.getCarePlanActivities(props.patientId)
    linkformMode.value = false
}

const weekRangeText = computed(() => {
    return startWeek.value == endWeek.value ? startWeek.value : startWeek.value+' - '+endWeek.value
})
</script>