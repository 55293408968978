<template>
    <div>
        <div class="mb-5">
            <Title :text="langStore.lang.mini_nutritional_assessment.webview.self_view_html"/>
        </div>

        <OptionsWithEmoji 
            v-model="store.formData.self_view"
            @selected="submit"
            :options="options" 
            bg-color="#ffffff"
            text-color="#1F2E99"
            active-bg="#019E94"
            active-text="#ffffff"
        />

        <SuccessAnimation ref="success" :callback="() => createUpdateUrlParam('completed', true)" v-show="showSuccess"/>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useMalnutritionStore } from '../../../stores/malnutrition';
import { useLangStore } from '../../../stores/lang';
import OptionsWithEmoji from '../../OptionsWithEmoji.vue';
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import SuccessAnimation from '../../SuccessAnimation.vue';
import Title from '../Title.vue';

const store = useMalnutritionStore();
const langStore = useLangStore();

const props = defineProps(['options'])

const options = [
    {
        value: props.options.malnourished,
        text: langStore.lang.mini_nutritional_assessment.webview.self_view_options.malnourished,
        emoji: '😕'
    },
    {
        value: props.options.uncertain,
        text: langStore.lang.mini_nutritional_assessment.webview.self_view_options.uncertain,
        emoji: '❓'
    },
    {
        value: props.options.no_problem,
        text: langStore.lang.mini_nutritional_assessment.webview.self_view_options.no_problem,
        emoji: '❌'
    },
]

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

const success = ref(null)
const showSuccess = ref(false)

const submit = () => {
    store.save(token).then(async () => {
        showSuccess.value = true
        success.value.animation.play()
    })
}
</script>