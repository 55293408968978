<template>
    <div class="h-100vh d-flex" :class="bgClass" :style="{ paddingTop: paddingTop(), paddingBottom: paddingBottom() }">
        <div class="container px-16px flex-fill">
            <div class="h-100 d-flex flex-column">
                <div class="mb-4 d-flex align-items-center justify-content-between">
                    <back-button :text="langStore.lang?.views?.back" @click="store.prevStep" />
                    <close-button @click="createUpdateUrlParam('exit', true)" />
                </div>
                <div class="mb-4" v-if="store.step != 3">
                    <Subtitle :text="langStore.lang.discharge_plan.webview.title_html" />
                </div>
                <div class="mb-4" v-if="store.step != 3">
                    <MultipartProgressBar :parts="2" :active="active" color="#b8a1ff" active-color="#7345FE" />
                </div>

                <CommunitySupport v-if="store.step == 1" />
                <CommunitySupportDesc v-if="store.step == 2" />
                <AgreeArrangements v-if="store.step == 3" />
                <WhoIAm :options="whoIAmOptions" v-if="store.step == 4" />
                <AfterSurgery v-if="store.step == 5" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { useDischargeConsentStore } from '../../../stores/discharge_consent';
import { useLangStore } from '../../../stores/lang';
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import BackButton from '../../BackButton.vue';
import CloseButton from '../../CloseButton.vue';
import { computed } from 'vue';
import Subtitle from '../Subtitle.vue';
import MultipartProgressBar from '../../MultipartProgressBar.vue';
import CommunitySupport from './CommunitySupport.vue';
import CommunitySupportDesc from './CommunitySupportDesc.vue';
import AgreeArrangements from './AgreeArrangements.vue';
import WhoIAm from './WhoIAm.vue';
import AfterSurgery from './AfterSurgery.vue';
import { paddingTop, paddingBottom } from '../../../utils/webview_padding_helper';

const store = useDischargeConsentStore();
const langStore = useLangStore();

const props = defineProps([
    'consent',
    'lang',
    'whoIAmOptions',
])

if (props.consent != '') {
    store.formData = JSON.parse(props.consent)
}

const whoIAmOptions = JSON.parse(props.whoIAmOptions)

langStore.lang = JSON.parse(props.lang)

const isSecondSection = computed(() => store.step > 3)
const isFirstSection = computed(() => store.step <= 3)

const active = computed(() => {
    let res;

    switch (true) {
        case isFirstSection.value:
            res = 1
            break;
        case isSecondSection.value:
            res = 2
            break;
    }

    return res;
})

const bgClass = computed(() => {
    if (store.step == 3) {
        return 'light-purple-bg'
    }
    return 'webview-purple-blur-bg'
})
</script>