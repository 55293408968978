<template>
    <div>
        <div class="mb-4">
            <Title :text="langStore.lang.discharge_plan.webview.i_am+':'"/>
        </div>

        <OptionsWithCheckbox
            v-model="store.formData.who_i_am" 
            :options="props.options"
            @selected="selected"
            label="label"
            value="value"
        />
    </div>
</template>
<script setup>
import { useLangStore } from '../../../stores/lang';
import { useDischargeConsentStore } from '../../../stores/discharge_consent';
import OptionsWithCheckbox from '../../OptionsWithCheckbox.vue';
import Title from '../Title.vue';

const props = defineProps(['options'])


const store = useDischargeConsentStore();
const langStore = useLangStore();

const selected = val => {
    store.nextStep()
}
</script>