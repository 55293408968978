<template>
    <div>
        <div v-for="option in options">
            <div 
                class="btn btn-white rio-pdq-button w-100 text-start px-4 mb-3 d-flex align-items-center" 
                :class="{ 'active' : active == option[props.value] }" 
                @click="select(option[props.value])"
            >
                <div class="d-flex align-items-center justify-content-between w-100">
                    <div>
                        {{ option[props.label] }}
                    </div>
                    <div>
                        <img :src="active == option[props.value] ? checked : placeholder">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import placeholder from '@images/rio-checked-placeholder.svg'
import checked from '@images/rio-checked.svg'
import { ref } from 'vue';

const props = defineProps(['options', 'label', 'value', 'modelValue'])
const emit = defineEmits(['update:modelValue', 'selected'])

const active = ref(props.modelValue)

const select = value => {
    active.value = value
    emit('update:modelValue', value)
    emit('selected', value)
}
</script>