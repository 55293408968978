import { defineStore } from "pinia/dist/pinia.cjs";
import { ref } from "vue";
import { createUpdateUrlParam } from '../utils/create_update_url_param';
import ApiService from "../services/api_service";

export const usePmhStore = defineStore('pmh', () => {
    const step = ref(1)

    const formData = ref({
        blood_pressure_history: null,
        blood_pressure_history_desc: null,
        blood_pressure_medication: null,
        blood_pressure_medication_desc: null,
        conditions_desc: null,
        current_or_past_conditions: null,
        diabetes_history: null,
        family_conditions: null,
        family_conditions_desc: null
    })

    const nextStep = () => {
        setTimeout(() => {
            if(step.value == 4 && formData.value.blood_pressure_history === false){
                step.value = 6;
                return;
            }
            step.value++
        }, 500)
    }

    const prevStep = () => {
        if (step.value == 1) {
            createUpdateUrlParam('exit', true)
            return;
        }
        
        step.value--
    }

    const save = (token) => {
        return new Promise((resolve, reject) => {
            ApiService.post('/p/pmh', { ...formData.value, token: token }).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    return {
        step,
        formData,
        nextStep,
        prevStep,
        save
    }
})