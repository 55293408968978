import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="anaesthetic-checklist"
export default class extends Controller {

  static values = {
    step: Number
  }

  connect() {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === 'value') {
              this.checkSectionsCompletion()
            }
        });
    });

    const inputs = document.querySelectorAll('input, select')

    inputs.forEach(input => observer.observe(input, { attributes: true }))

    this.checkSectionsCompletion()

    document.addEventListener('turbo:submit-end', () => window.scrollTo(0, 0))
  }

  checkHeartRate(){
    const input = this.element.querySelector('input[name="heart_rate"]')
    const indicator = document.querySelector('#heart-rate-accordion .completion-indicator')

    let allChecked = input.value != ''

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkBloodPressure(){
    const systolic = this.element.querySelector('input[name="systolic"]')
    const diastolic = this.element.querySelector('input[name="diastolic"]')
    const indicator = document.querySelector('#blood-pressure-accordion .completion-indicator')

    let allChecked = systolic.value != '' && diastolic.value != ''

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkECG(){
    const radioInput = this.element.querySelector('input[name="ecg"]')
    // const desc = this.element.querySelector('input[name="ecg_desc"]')
    const indicator = document.querySelector('#ecg-accordion .completion-indicator')

    let allChecked = radioInput.value != '' //&& desc.value != ''

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkRespiratoryRate(){
    const input = this.element.querySelector('input[name="respiratory_rate"]')
    const indicator = document.querySelector('#respiratory-rate-accordion .completion-indicator')

    let allChecked = input.value != ''

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkPeakFlow(){
    const input = this.element.querySelector('input[name="peak_flow"]')
    const indicator = document.querySelector('#peak-flow-accordion .completion-indicator')

    let allChecked = input.value != ''

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkOxygenSaturation(){
    const input = this.element.querySelector('input[name="oxygen_saturation"]')
    const indicator = document.querySelector('#oxygen-saturation-accordion .completion-indicator')

    let allChecked = input.value != ''

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkWeightHeight(){
    const weight = this.element.querySelector('input[name="weight"]')
    const height = this.element.querySelector('input[name="height"]')
    const indicator = document.querySelector('#height-weight-accordion .completion-indicator')

    let allChecked = weight.value != '' && height.value != ''

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkUrineAnalysis(){
    const radioInput = this.element.querySelector('input[name="urine_analysis"]')
    const indicator = document.querySelector('#urine-analysis-accordion .completion-indicator')

    let allChecked = radioInput.value != ''

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkAlcohol(){
    const input = this.element.querySelector('input[name="alcohol_weekly_count"]')
    const indicator = document.querySelector('#alcohol-accordion .completion-indicator')

    let allChecked = input.value != ''

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkSmoking(){
    const radioInput = this.element.querySelector('input[name="stopped_smoking"]')
    const count = this.element.querySelector('input[name="cigarette_daily_count"]')
    const indicator = document.querySelector('#smoking-accordion .completion-indicator')
    const countContainer = this.element.querySelector('#cigarette-daily-count')

    let allChecked;

    if(radioInput.value == '1'){
      allChecked = radioInput.value != ''
      countContainer.classList.add('d-none')
    }
    if(radioInput.value == '0'){
      allChecked = radioInput.value != '' && count.value != ''
      countContainer.classList.remove('d-none')
    }

    
    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkBloodSugar(){
    const input = this.element.querySelector('input[name="blood_sugar"]')
    const indicator = document.querySelector('#blood-sugar-accordion .completion-indicator')

    let allChecked = input.value != ''

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkMrsa(){
    const radioInput = this.element.querySelector('input[name="mrsa"]')
    const mrsaContact = this.element.querySelector('input[name="mrsa_contact"]')
    const mrsaDate = this.element.querySelector('input[name="mrsa_date"]')
    const swabArea = this.element.querySelector('select[name="swab_area"]')
    const swabDate = this.element.querySelector('input[name="swab_date"]')
    const indicator = document.querySelector('#mrsa-status .completion-indicator')
    const detailsContainer = this.element.querySelector('#mrsa-details')
    let allChecked;

    if(radioInput.value == '1'){
      allChecked = radioInput.value != '' && mrsaContact.value != '' && mrsaDate.vale != '' && swabArea.value != '' && swabDate.value != ''
      detailsContainer.classList.remove('d-none')
    }else{
      allChecked = radioInput.value != ''
      detailsContainer.classList.add('d-none')
    }

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkOrthopnoea(){
    const buttonInputs = document.querySelectorAll('#orthopnoea input[type="hidden"]')
    const textInput = document.querySelector('#orthopnoea input[name="maximum_walking_distance"]')
    const numberInput = document.querySelector('#orthopnoea input[name="pillows_count"]')
    const selects = document.querySelectorAll('#orthopnoea select')
    const indicator = document.querySelector('#orthopnoea .completion-indicator')

    let buttonsChecked = true

    buttonInputs.forEach(input => {
      if(!['0', '1'].includes(input.value)){
        buttonsChecked = false
      }
    })

    let selectsChecked = true;

    selects.forEach(input => {
      if(!input.value){
        selectsChecked = false
      }
    })

    const allChecked = buttonsChecked && selectsChecked && textInput.value && numberInput.value

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkInvestigationType(){
    const buttonInputs = document.querySelectorAll('#investigation-type input[type="hidden"]')
    const textInput = document.querySelector('#investigation-type input[name="other_investigations"]')
    const indicator = document.querySelector('#investigation-type .completion-indicator')

    let buttonsChecked = true

    buttonInputs.forEach(input => {
      if(!['0', '1'].includes(input.value)){
        buttonsChecked = false
      }
    })

    const allChecked = buttonsChecked && textInput.value

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkGeneralAppearance(){
    const buttonInputs = document.querySelectorAll('#general-appearance input[type="hidden"]')
    const indicator = document.querySelector('#general-appearance .completion-indicator')

    let allChecked = true

    buttonInputs.forEach(input => {
      if(!['0', '1'].includes(input.value)){
        allChecked = false
      }
    })

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkRespiratorySystem(){
    const buttonInputs = document.querySelectorAll('#respiratory-system input[type="hidden"]')
    const radioInputs = document.querySelectorAll('#respiratory-system input[type="radio"]')
    const selects = document.querySelectorAll('#respiratory-system select')
    const textInputs = document.querySelectorAll('#respiratory-system input[type="text"]')
    const indicator = document.querySelector('#respiratory-system .completion-indicator')

    let buttonsChecked = true

    buttonInputs.forEach(input => {
      if(!['0', '1'].includes(input.value)){
        buttonsChecked = false
      }
    })

    const checkedRadios = Array.from(radioInputs).filter(input => input.checked)

    let selectsChecked = true;

    selects.forEach(input => {
      if(!input.value){
        selectsChecked = false
      }
    })

    let textsFilled = true;

    textInputs.forEach(input => {
      if(!input.value){
        textsFilled = false
      }
    })

    const allChecked = buttonsChecked && checkedRadios.length == 2 && selectsChecked && textsFilled

    if(allChecked){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkAsaStatus(){
    const select = document.querySelector('#asa-status select')
    const indicator = document.querySelector('#asa-status .completion-indicator')

    if(select.value){
      indicator.classList.add('checked')
    }else{
      indicator.classList.remove('checked')
    }
  }

  checkSectionsCompletion(){
    if(this.stepValue == 1){
      this.checkHeartRate();
      this.checkBloodPressure();
      this.checkECG();
      this.checkRespiratoryRate();
      this.checkPeakFlow();
      this.checkOxygenSaturation();
      this.checkWeightHeight();
      this.checkUrineAnalysis();
      this.checkAlcohol();
      this.checkSmoking();
      this.checkBloodSugar();
      this.checkMrsa()
    }
    if(this.stepValue == 2){
      this.checkOrthopnoea()
    }
    if(this.stepValue == 3){
      this.checkInvestigationType()
    }
    if(this.stepValue == 4){
      this.checkGeneralAppearance()
    }
    if(this.stepValue == 5){
      this.checkRespiratorySystem()
      this.checkAsaStatus()
    }
  }
}
