<template>
    <div class="position-fixed top-0 left-0 w-100 h-100 green-bg d-flex flex-column pt-3 px-3 pb-20px">
        <div class="text-center my-5">
            <img :src="getImageURL('fasting-outro.svg')">
        </div>
        <div class="text-center mb-4">
            <Title :text="langStore.lang.fasting.webview.outro_title"/>
        </div>
        <div class="text-center">
            <div class="fs-18px text-dark-blue ff-montserat mb-3" v-html="langStore.lang.fasting.webview.outro_text_html"></div>
        </div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="submit">{{ langStore.lang.views.back_to_preop }}</button>
        </div>

        <SuccessAnimation ref="success" :callback="() => createUpdateUrlParam('completed', true)" v-show="showSuccess"/>
    </div>
</template>
<script setup>
import { useFastingStore } from '../../../stores/fasting';
import { useLangStore } from '../../../stores/lang';
import Title from '../Title.vue';
import SuccessAnimation from '../../SuccessAnimation.vue';
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import { ref } from 'vue';
import { getImageURL } from '../../../utils/image_url_helper';

const store = useFastingStore();
const langStore = useLangStore();

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

const success = ref(null)
const showSuccess = ref(false)

const submit = () => {
    store.save(token).then(() => {
        showSuccess.value = true
        success.value.animation.play()
    })
}
</script>
<style scoped>
.green-bg{
    background: #DFFFF4;
}
</style>