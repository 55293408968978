<template>
    <div class="flex-fill d-flex flex-column">
        <div class="text-center mb-4">
            <img :src="getImageURL('pharmacist-with-star.svg')">
        </div>
        <div class="text-center mb-5">
            <Title :text="langStore.lang.medications.webview.your_medication_details" />
        </div>
        <div class="d-flex justify-content-between align-items-center fs-18px ff-montserat mb-3">
            <div class="text-dark-blue">{{ langStore.lang.medications.webview.drug_name }}</div>
            <div class="text-dark-gray">{{ store.formData.name }}</div>
        </div>

        <div class="d-flex justify-content-between align-items-center fs-18px ff-montserat mb-3">
            <div class="text-dark-blue">{{langStore.lang.medications.webview.dosage}}</div>
            <div class="text-dark-gray">{{ store.formData.dose }}</div>
        </div>

        <div class="d-flex justify-content-between align-items-center fs-18px ff-montserat mb-3">
            <div class="text-dark-blue">{{langStore.lang.medications.webview.frequency}}</div>
            <div class="text-dark-gray">{{ store.getFrequencyName(store.formData.frequency) }}</div>
        </div>

        <div class="d-flex justify-content-between align-items-center fs-18px ff-montserat mb-3">
            <div class="text-dark-blue">{{langStore.lang.medications.webview.details}}</div>
            <div class="text-dark-gray">{{ store.formData.desc }}</div>
        </div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="submit">{{ langStore.lang.views.continue }}</button>
        </div>
    </div>
</template>
<script setup>
import { useLangStore } from '../../../stores/lang';
import { useMedicationsStore } from '../../../stores/medications';
import { getImageURL } from '../../../utils/image_url_helper';
import Title from '../Title.vue';

const langStore = useLangStore();
const store = useMedicationsStore();

const submit = () => {
    const existingMedication = store.medications.find(medication => medication.name === store.formData.name);
    
    if(existingMedication) {
        existingMedication.dose = store.formData.dose;
        existingMedication.frequency = store.formData.frequency;
        existingMedication.desc = store.formData.desc;
    } else {
        store.medications.push({...store.formData});
    }
    
    store.resetFormData();
    store.step = 1;
}
</script>
<style scoped>
</style>