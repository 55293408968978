<template>
    <div>
        <div class="mb-5">
            <Title :text="langStore.lang.mini_nutritional_assessment.webview.health_status_compared_to_others_html"/>
        </div>

        <OptionsWithEmoji 
            v-model="store.formData.health_status_compared_to_others"
            @selected="selected"
            :options="options" 
            bg-color="#ffffff"
            text-color="#1F2E99"
            active-bg="#019E94"
            active-text="#ffffff"
        />
    </div>
</template>
<script setup>
import { useMalnutritionStore } from '../../../stores/malnutrition';
import { useLangStore } from '../../../stores/lang';
import OptionsWithEmoji from '../../OptionsWithEmoji.vue';
import Title from '../Title.vue';

const store = useMalnutritionStore();
const langStore = useLangStore();

const props = defineProps(['options'])

const options = [
    {
        value: props.options.not_good,
        text: langStore.lang.mini_nutritional_assessment.webview.health_status_options.not_good,
        emoji: '😓'
    },
    {
        value: props.options.good,
        text: langStore.lang.mini_nutritional_assessment.webview.health_status_options.good,
        emoji: '😕'
    },
    {
        value: props.options.dont_know,
        text: langStore.lang.mini_nutritional_assessment.webview.health_status_options.dont_know,
        emoji: '❓'
    },
    {
        value: props.options.better,
        text: langStore.lang.mini_nutritional_assessment.webview.health_status_options.better,
        emoji: '🙂'
    },
]

const selected = val => {
    store.nextStep()
}
</script>