<template>
    <div class="h-100 d-flex flex-column">
        <div class="text-center mb-5">
            <img :src="getImageURL('remote-team--work-office-remote-team.svg')">
        </div>
        <div class="mb-4">
            <Title :text="langStore.lang.discharge_plan.webview.community_support_explain_title_html" />
        </div>
        <div class="fs-18px ff-montserat text-dark-blue mb-5" v-html="langStore.lang.discharge_plan.webview.community_support_explain_subtitle_html"></div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="store.nextStep">{{ langStore.lang.views.continue }}</button>
        </div>
    </div>
</template>
<script setup>
import { useLangStore } from '../../../stores/lang';
import { useDischargeConsentStore } from '../../../stores/discharge_consent';
import { getImageURL } from '../../../utils/image_url_helper';
import Title from '../Title.vue';

const store = useDischargeConsentStore()
const langStore = useLangStore();


</script>