<template>
    <div class="position-fixed w-100 h-100 top-0 left-0 webview-success-animation">
        <lottie-player
            class="h-100"
            mode="normal"
            :src="getImageURL('webview-success-animation.json')"
            ref="animation"
        >
        </lottie-player>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { getImageURL } from '../utils/image_url_helper';

const animation = ref(null)

const props = defineProps(['callback'])

onMounted(() => {
    animation.value.addEventListener('complete', () => {
        props.callback()
    })
})

defineExpose({
    animation
})

</script>
