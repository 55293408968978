<template>
    <div class="flex-fill">
        <div class="d-flex flex-column h-100">
            <div class="text-center mb-4">
                <img :src="getImageURL('faq.svg')">
            </div>

            <div class="mb-4">
                <Title :text="langStore.lang.allergies.webview.tell_us_your_allergy" />
            </div>

            <div>
                <div class="mb-5">
                    <div class="fs-18px text-gray11 mb-1 fw-300">{{ langStore.lang.allergies.webview.type_name }}</div>
                    <input type="text" class="form-control" v-model="store.formData.allergy">
                </div>
                <div>
                    <div class="mb-5">
                        <Title :text="langStore.lang.allergies.webview.tell_us_severity" />
                    </div>
                    <tc-range-slider id="slider-1"></tc-range-slider>
                    <div class="d-flex justify-content-between mt-3 text-dark-blue fs-16px ff-montserat">
                        <div>{{ store.getSeverityName(1) }}</div>
                        <div>{{ store.getSeverityName(2) }}</div>
                        <div>{{ store.getSeverityName(3) }}</div>
                    </div>
                </div>
            </div>

            <div class="mt-auto">
                <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="submit" :disabled="!store.formData.allergy">{{ langStore.lang.views.continue }}</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import 'toolcool-range-slider/dist/plugins/tcrs-marks.min.js'
import 'toolcool-range-slider/dist/toolcool-range-slider.min.js'
import { useAllergiesStore } from '../../../stores/allergies';
import { useLangStore } from '../../../stores/lang';
import { onMounted, ref } from "vue";
import { getImageURL } from '../../../utils/image_url_helper';
import Title from '../Title.vue';

const store = useAllergiesStore();
const langStore = useLangStore();

onMounted(() => {
    // get the reference
    const $slider = document.getElementById('slider-1');
    $slider.sliderHeight = '16px';
    $slider.sliderWidth = '100%';
    $slider.sliderBg = 'linear-gradient(90deg, #7BD5FF 0%, #FFC230 31.43%, #E34548 68.69%, #6B43CB 97.59%)';
    $slider.sliderBgHover = 'linear-gradient(90deg, #7BD5FF 0%, #FFC230 31.43%, #E34548 68.69%, #6B43CB 97.59%)';
    $slider.sliderBgFill = 'transparent';
    $slider.pointerHeight = '36px';
    $slider.pointerWidth = '36px';
    $slider.pointerBg = 'transparent';
    $slider.pointerBgHover = 'transparent';
    $slider.pointerBorder = '10px solid #fff';
    $slider.pointerBorderHover = '10px solid #fff'; 
    $slider.pointerBgFocus = 'transparent';
    $slider.pointerBorderFocus = '10px solid #fff';
    $slider.value = store.formData.severity;
    
    // change data
    $slider.data = [1, 2, 3];

    $slider.addCSS(`
        .pointer[aria-valuenow="1"] .pointer-shape{
            transform: translateX(-30%);
        }
        .pointer[aria-valuenow="3"] .pointer-shape{
            transform: translateX(-70%);
        }
    `);

    $slider.addEventListener('change', (evt) => {
        store.formData.severity = evt.detail.value;
    });
})

const submit = () => {
    const existingAllergy = store.allergies.find(allergy => allergy.allergy === store.formData.allergy);
    
    if(existingAllergy) {
        existingAllergy.severity = store.formData.severity;
    } else {
        store.allergies.push({...store.formData});
    }
    
    store.resetFormData();
    store.step = 1;
}
</script>
<style scoped></style>