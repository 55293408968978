import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="allergy-modal"
export default class extends Controller {

  connect() {
    // this.element.addEventListener('turbo:submit-end', () => this.hideModal())

    const modal = this.element

    modal.addEventListener('hidden.bs.modal', () => {
      const allergy = modal.querySelector('input[name="allergy"')
      this.removeError(allergy)

      const severity = modal.querySelector('select[name="severity"')
      this.removeError(severity)

      modal.querySelectorAll('.error').forEach(error => error.remove())
    })
  }

  hideModal(){
    const button = document.getElementById('close-allergy-modal')
    button.click();
  }

  removeError(input){
    input.value = '';
    input.classList.remove('is-invalid')
    input.classList.add('border-light')
  }
}
