import { defineStore } from "pinia/dist/pinia.cjs";
import { ref } from "vue";
import { createUpdateUrlParam } from '../utils/create_update_url_param';
import ApiService from "../services/api_service";

export const useMalnutritionStore = defineStore('malnutrition', () => {
    const step = ref(1)

    const formData = ref({
        bmi: null,
        cc: null,
        dairy_product_daily: null,
        declined_food: null,
        feeding_mode: null,
        fluid_daily: null,
        full_meals_count: null,
        had_psychological_stress: null,
        health_status_compared_to_others: null,
        lives_independently: null,
        mac: null,
        meat_daily: null,
        mobility: null,
        more_than_three_drugs_daily: null,
        neuropsychological_problems: null,
        pressure_sores: null,
        self_view: null,
        two_or_more_eggs_weekly: null,
        two_or_more_vegetables_daily: null,
        weight_loss: null
    })

    const nextStep = () => {
        step.value++
    }

    const prevStep = () => {
        if (step.value == 1) {
            createUpdateUrlParam('exit', true)
            return;
        }
        
        step.value--
    }

    const save = (token) => {
        return new Promise((resolve, reject) => {
            ApiService.post('/p/malnutrition', { ...formData.value, token: token }).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    return {
        step,
        formData,
        nextStep,
        prevStep,
        save
    }
})