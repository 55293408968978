<template>
    <div class="flex-fill d-flex flex-column">
        <Title :text="langStore.lang.allergies.webview.your_allergies"/>
        <div class="flex-fill d-flex flex-column" :class="{'d-flex justify-content-center align-items-center': store.allergies.length == 0}">
            <div class="my-4">
                <div v-if="store.allergies.length > 0">
                    <div class="d-flex justify-content-between align-items-center bg-white input-border-radius shadow-custom p-3 mb-3" v-for="allergy in store.allergies">
                        <div>
                            <div class="text-dark-blue fs-24px fw-600 ff-montserat">{{ allergy.allergy }}</div>
                            <div class="text-gray fs-16px ff-montserat">{{ store.getSeverityName(allergy.severity) }}</div>
                        </div>
                        <div>
                        <a href="javascript:;" class="ms-3" @click="store.destroy(allergy?.id, allergy.allergy, token)">
                            <img :src="getImageURL('trash-02.svg')">
                        </a>
                        </div>
                    </div>
                </div>

                <a hrefe="javascript:;" class="text-center text-decoration-none" @click="store.nextStep">
                    <div class="mb-2">
                        <img :src="getImageURL('purple-plus.svg')">
                    </div>
                    <div class="text-dark-blue fs-16px ff-montserat">{{ langStore.lang.allergies.webview.add_allergy }}</div>
                </a>
            </div>    

            <div class="mt-auto" v-if="store.allergies.length > 0">
                <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="submit">{{ langStore.lang.allergies.webview.save_allergies }}</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useAllergiesStore } from '../../../stores/allergies';
import { useLangStore } from '../../../stores/lang';
import Title from '../Title.vue';
import { getImageURL } from '../../../utils/image_url_helper';

const store = useAllergiesStore();
const langStore = useLangStore();

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

const submit = () => {
    store.save(token).then(async () => {
        store.step = 3
    })
}

</script>