<template>
    <div class="position-fixed top-0 left-0 w-100 h-100 purple-blue-bg d-flex flex-column pt-3 px-3 pb-20px">
        <div class="text-center my-5">
            <img :src="getImageURL('habits-outro.svg')">
        </div>
        <div class="text-center mb-4">
            <Title :text="langStore.lang.habits.webview.outro_title"/>
        </div>
        <div class="text-center">
            <div class="fs-18px text-dark-blue ff-montserat mb-3">{{ langStore.lang.habits.webview.outro_text1 }}</div>
            <div class="fs-18px text-dark-blue ff-montserat">{{ langStore.lang.habits.webview.outro_text2 }}</div>
        </div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="submit">{{ langStore.lang.views.back_to_preop }}</button>
        </div>

        <SuccessAnimation ref="success" :callback="() => createUpdateUrlParam('completed', true)" v-show="showSuccess"/>
    </div>
</template>
<script setup>
import { useHabitsStore } from '../../../stores/habits';
import { useLangStore } from '../../../stores/lang';
import Title from '../Title.vue';
import SuccessAnimation from '../../SuccessAnimation.vue';
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import { ref } from 'vue';
import { getImageURL } from '../../../utils/image_url_helper';

const store = useHabitsStore();
const langStore = useLangStore();

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

const success = ref(null)
const showSuccess = ref(false)

const submit = () => {
    if(store.formData.haemoglobin_taken_at){
        store.save(token).then(() => {
            showSuccess.value = true
            success.value.animation.play()
        })
    }
}
</script>
<style scoped>
.purple-blue-bg{
    background: #F5F0FE;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="393" height="625" viewBox="0 0 393 625" fill="none"><g opacity="0.6" filter="url(%23filter0_f_770_11005)"><circle cx="125.5" cy="243.499" r="101.5" fill="%2348D5E8"/></g><defs><filter id="filter0_f_770_11005" x="-256" y="-138.001" width="763" height="763" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="140" result="effect1_foregroundBlur_770_11005"/></filter></defs></svg>');
    background-repeat: no-repeat;
}
</style>