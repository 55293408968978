<template>
    <div>
        <div class="mb-4">
            <Title :text="langStore.lang.habits.webview.haemoglobin_taken_at"/>
        </div>
        <div class="mb-4">
            <div class="fs-18px text-gray11 mb-1 fw-300">{{ langStore.lang.views.select_date }}</div>
            <input type="date" ref="date" class="form-control mb-3" :max="moment().format('YYYY-MM-DD')" v-model="store.formData.haemoglobin_taken_at" @blur="select">
        </div>
    </div>
</template>
<script setup>
import moment from 'moment'
import { useHabitsStore } from '../../../stores/habits';
import { useLangStore } from '../../../stores/lang';
import Title from '../Title.vue';
import { ref, watch } from 'vue';

const store = useHabitsStore();
const langStore = useLangStore();

const date = ref(null)

watch(() => store.formData.haemoglobin_taken_at, () => {
    date.value.setAttribute('max', new Date().toISOString().split('T')[0])
})

const select = () => {
    if(store.formData.haemoglobin_taken_at){
        store.nextStep()
    }
}
</script>