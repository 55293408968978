import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap'
// Connects to data-controller="delete-medication"
export default class extends Controller {
  connect() {
    this.setModal();
    document.addEventListener('turbo:submit-end', () => this.modal.hide())
  }

  showDeleteModal(e){
    const id = e.currentTarget.getAttribute('data-id')
    this.modalEl.querySelector("input[name='medication_id']").value = id
    
    this.modal.show()
  }

  setModal(){
    this.modalEl = document.getElementById('confirm-delete-medication-modal')
    this.modal = new Modal(this.modalEl)
  }

  hideModal(){
    this.modal.hide();
  }
}
