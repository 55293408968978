import { defineStore } from "pinia/dist/pinia.cjs";
import { ref } from "vue";
import { createUpdateUrlParam } from '../utils/create_update_url_param';
import ApiService from "../services/api_service";

export const useFastingStore = defineStore('fasting', () => {
    const step = ref(1)

    const formData = ref({
        carb_load: null,
        fluid_date: null,
        fluid_time: null,
        food_date: null,
        food_time: null,
    })

    const nextStep = () => {
        setTimeout(() => {
            step.value++
        }, 500)
    }

    const prevStep = () => {
        if (step.value == 1) {
            createUpdateUrlParam('exit', true)
            return;
        }
        
        step.value--
    }

    const save = (token) => {
        return new Promise((resolve, reject) => {
            ApiService.post('/p/fasting', { ...formData.value, token: token }).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    return {
        step,
        formData,
        nextStep,
        prevStep,
        save
    }
})