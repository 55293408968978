import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="patient-fasting"
export default class extends Controller {
  connect() {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === 'value') {
              this.updateProgressBar();
            }
        });
    });

    const inputs = this.element.querySelectorAll('input')

    inputs.forEach(input => observer.observe(input, { attributes: true }))

    this.updateProgressBar();

    document.addEventListener('turbo:submit-end', () => window.scrollTo(0, 0))
  }

  updateProgressBar(){
    const totalQuestions = 5;
    let totalAnswered = 0;

    const fluidDate = this.element.querySelector('input[name="fluid_date"]')
    const fluidTime = this.element.querySelector('input[name="fluid_time"]')
    const foodDate = this.element.querySelector('input[name="food_date"]')
    const foodTime = this.element.querySelector('input[name="food_time"]')
    const carbLoad = this.element.querySelector('input[name="carb_load"]')

    if(fluidDate.value) totalAnswered++;
    if(fluidTime.value) totalAnswered++;
    if(foodDate.value) totalAnswered++;
    if(foodTime.value) totalAnswered++;
    if(carbLoad.value) totalAnswered++;

    const completedPercent = totalAnswered / totalQuestions * 100;

    const progressBar = this.element.querySelector('.purple-progress-bar .item')
    progressBar.style.width = completedPercent+'%'
  }
}
