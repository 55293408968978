import { Controller } from "@hotwired/stimulus"
import { initVueApp } from "../utils/init_vue";

// Connects to data-controller="fasting-webview"
export default class extends Controller {
  connect() {
    const app = initVueApp();
    app.mount(this.element);
  }
}
