<template>
    <div class="d-flex">
        <div class="yes-no-with-thumb-item me-2 d-flex align-items-center justify-content-center" :class="{'active' : active}" :style="{ background: props.bgColor, color: props.textColor }" @click="selected(true)">
            <div>
                <div class="text-center">👍</div>
                <div class="fs-24px text fw-600 ff-motserat" :style="{ color: props.textColor }">{{ props.yesLabel }}</div>
            </div>
        </div>
        <div class="yes-no-with-thumb-item ms-2 d-flex align-items-center justify-content-center" :class="{'active' : active == false }" :style="{ background: props.bgColor }" @click="selected(false)">
            <div>
                <div class="text-center">👎</div>
                <div class="fs-24px text fw-600 ff-motserat" :style="{ color: props.textColor }">{{ props.noLabel }}</div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { ref } from 'vue';
    const props = defineProps([
        'modelValue',
        'options',
        'bgColor',
        'textColor',
        'activeBg',
        'activeText',
        'yesLabel',
        'noLabel'
    ])

    const emit = defineEmits(['selected', 'update:modelValue'])

    const active = ref(props.modelValue)

    const selected = value => {
        active.value = value
        emit('update:modelValue', value)
        emit('selected', value)
    }
</script>
<style scoped>
    .yes-no-with-thumb-item.active, .yes-no-with-thumb-item:active{
        background: v-bind('props.activeBg')!important;
    }

    .yes-no-with-thumb-item.active .text, .yes-no-with-thumb-item:active .text{
        color: v-bind('props.activeText')!important;
    }
</style>