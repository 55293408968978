<template>
    <div class="pb-20px">
        <div class="mb-5 after-surgery-container">
            <div class="mb-3 bg-white p-3 input-border-radius shadow">
                <div class="d-flex align-items-center mb-3">
                    <div class="me-3">
                        <img :src="getImageURL('discharge-Car.svg')">
                    </div>
                    <div class="text-dark-blue fw-600 fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.driving_title }}</div>
                </div>
                <div class="text-dark-blue fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.driving_desc }}</div>
            </div>
            <div class="mb-3 bg-white p-3 input-border-radius shadow">
                <div class="d-flex align-items-center mb-3">
                    <div class="me-3">
                        <img :src="getImageURL('discharge-pencil-line.svg')">
                    </div>
                    <div class="text-dark-blue fw-600 fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.signing_title }}</div>
                </div>
                <div class="text-dark-blue fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.signing_desc }}</div>
            </div>
            <div class="mb-3 bg-white p-3 input-border-radius shadow">
                <div class="d-flex align-items-center mb-3">
                    <div class="me-3">
                        <img :src="getImageURL('discharge-Wine.svg')">
                    </div>
                    <div class="text-dark-blue fw-600 fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.alcohol_title }}</div>
                </div>
                <div class="text-dark-blue fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.alcohol_desc }}</div>
            </div>
            <div class="mb-3 bg-white p-3 input-border-radius shadow">
                <div class="d-flex align-items-center mb-3">
                    <div class="me-3">
                        <img :src="getImageURL('discharge-home-03.svg')">
                    </div>
                    <div class="text-dark-blue fw-600 fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.home_title }}</div>
                </div>
                <div class="text-dark-blue fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.home_desc }}</div>
            </div>
            <div class="mb-3 bg-white p-3 input-border-radius shadow">
                <div class="d-flex align-items-center mb-3">
                    <div class="me-3">
                        <img :src="getImageURL('discharge-face-content.svg')">
                    </div>
                    <div class="text-dark-blue fw-600 fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.staying_title }}</div>
                </div>
                <div class="text-dark-blue fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.staying_desc }}</div>
            </div>
            <div class="bg-white p-3 input-border-radius shadow">
                <div class="d-flex align-items-center mb-3">
                    <div class="me-3">
                        <img :src="getImageURL('discharge-star-06.svg')">
                    </div>
                    <div class="text-dark-blue fw-600 fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.belonging_title }}</div>
                </div>
                <div class="text-dark-blue fs-16px ff-montserrat">{{ langStore.lang.discharge_plan.webview.belonging_desc }}</div>
            </div>
        </div>

        <div class="mt-auto">
            <button class="btn btn-orange mobile w-100 h-56px input-border-radius" @click="select">{{ langStore.lang.discharge_plan.webview.i_read_all_and_agree }}</button>
        </div>

        <SuccessAnimation ref="success" :callback="() => createUpdateUrlParam('completed', true)" v-show="showSuccess"/>
    </div>
</template>
<script setup>
import { ref } from 'vue';
import { useDischargeConsentStore } from '../../../stores/discharge_consent';
import { useLangStore } from '../../../stores/lang';
import { createUpdateUrlParam } from '../../../utils/create_update_url_param';
import { getImageURL } from '../../../utils/image_url_helper';
import SuccessAnimation from '../../SuccessAnimation.vue';

const langStore = useLangStore();
const store = useDischargeConsentStore();

const select = () => {
    store.formData.after_surgery = true
    showAnimation()
}

const success = ref(null)
const showSuccess = ref(false)

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

const showAnimation = () => {
    showSuccess.value = true
    store.save(token).then(() => {
        success.value.animation.play()
    })
}
</script>