import { defineStore } from "pinia/dist/pinia.cjs";
import { ref } from "vue";
import { createUpdateUrlParam } from '../utils/create_update_url_param';
import ApiService from "../services/api_service";

export const usePreSurgeryChecklistStore = defineStore('pre-surgery-checklist', () => {
    const step = ref(1)

    const activities = ref([])

    const formData = ref({
        name: null,
        completed: null
    })

    const nextStep = () => {
        step.value++
    }

    const prevStep = () => {
        if (step.value == 1) {
            createUpdateUrlParam('exit', true)
            return;
        }
        
        step.value--
    }

    const save = (token) => {
        return new Promise((resolve, reject) => {
            ApiService.post('/p/pre_surgery_checklist', { ...formData.value, token: token }).then(({ data }) => {
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    const getActivities = (token) => {
        return new Promise((resolve, reject) => {
            ApiService.get('/p/pre_surgery_checklist/activities?token='+token).then(({ data }) => {
                activities.value = data
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    const markAsComplete = (id, token) => {
        return new Promise((resolve, reject) => {
            ApiService.put('/p/pre_surgery_checklist/'+id+'/mark_as_complete', { token: token }).then(({ data }) => {
                activities.value = data
                resolve(data)
            }).catch(({ response }) => {
                reject(response)
            })
        })
    }

    return {
        step,
        formData,
        nextStep,
        prevStep,
        save,
        activities,
        getActivities,
        markAsComplete
    }
})