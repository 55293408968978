import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pressure-sore"
export default class extends Controller {
  connect() {
    this.updateProgressBar()
    document.addEventListener('turbo:submit-end', () => window.scrollTo(0, 0))
  }

  checkIfAnySelected(id){
    const inputs = this.element.querySelectorAll("#"+id+" input[type='checkbox']");
    return Array.from(inputs).filter(input => input.checked).length > 0
  }

  updateProgressBar(){
    const answered = this.getAnswersCount();
    const total = 9;
    const completedPercent = answered / total * 100;

    const progressBar = this.element.querySelector('.purple-progress-bar .item')
    progressBar.style.width = completedPercent+'%'
  }

  getAnswersCount(){
    const fields = ['bmi', 'continence', 'skin', 'mobility', 'sex', 'age', 'appetite', 'special_risks', 'neurological_defects']
    let total = 0;

    fields.forEach(field => {
      if(this.checkIfAnySelected(field)){
        total++;
      }
    })

    return total;
  }
}
