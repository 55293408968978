import { Controller } from "@hotwired/stimulus"
import { sanitizeNumberInput } from '../utils/sanitize_input';

// Connects to data-controller="patient-habits"
export default class extends Controller {
  connect() {
    this.defineInputs()

    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === 'value') {
              this.checkSectionsCompletion()
            }
        });
    });

    const inputs = this.element.querySelectorAll('input')

    inputs.forEach(input => observer.observe(input, { attributes: true }))

    this.checkSectionsCompletion()

    document.addEventListener('turbo:submit-end', () => window.scrollTo(0, 0))
  }

  checkSection1(){
    const indicator = document.querySelector('#smoking-habits .completion-indicator');

    const toggleVisibility = (show) => {
        this.smokeDate.closest('.row').classList.toggle('d-none', !show);
        this.smokeUnits.closest('.row').classList.toggle('d-none', !show);
    };

    const allChecked = this.smoke.value === '0' || (['0', '1'].includes(this.smoke.value) && this.smokeDate.value && this.smokeUnits.value);

    toggleVisibility(this.smoke.value !== '0');
    indicator.classList.toggle('checked', allChecked);

    this.updateProgressBar()
  }

  checkSection2(){
    const indicator = document.querySelector('#drinking-habits .completion-indicator');

    const toggleVisibility = (show) => {
      this.drinkDate.closest('.row').classList.toggle('d-none', !show);
      this.drinkUnits.closest('.row').classList.toggle('d-none', !show);
    };

    const allChecked = this.drink.value === '0' || (['0', '1'].includes(this.drink.value) && this.drinkDate.value && this.drinkUnits.value);

    toggleVisibility(this.drink.value !== '0');
    indicator.classList.toggle('checked', allChecked);
    this.updateProgressBar()
  }

  checkSection3(){
    const buttonInputs = document.querySelectorAll('#anemia .yes-no-buttons input[type="hidden"]');
    const indicator = document.querySelector('#anemia .completion-indicator');

    let allChecked = true

    buttonInputs.forEach(input => {
      if(!['0', '1'].includes(input.value)){
        allChecked = false
      }
    })

    allChecked = allChecked && this.haemoglobin.value && this.haemoglobinDate.value
    indicator.classList.toggle('checked', allChecked);
    this.updateProgressBar()
  }

  checkSectionsCompletion(){
    this.checkSection1();
    this.checkSection2();
    this.checkSection3();
  }

  updateProgressBar(){
    let totalQuestions = 10

    if(this.drink.value == '0') totalQuestions -= 2;
    if(this.smoke.value == '0') totalQuestions -= 2;

    let answeredQuestions = 0;

    if(this.drink.value) answeredQuestions++;
    if(this.drink.value == '1'){
      if(this.drinkDate.value) answeredQuestions++;
      if(this.drinkUnits.value) answeredQuestions++;
    }
    if(this.smoke.value) answeredQuestions++;
    if(this.smoke.value == '1'){
      if(this.smokeDate.value) answeredQuestions++;
      if(this.smokeUnits.value) answeredQuestions++;
    }
    if(this.anemia.value) answeredQuestions++;
    if(this.iron.value) answeredQuestions++;
    if(this.haemoglobin.value) answeredQuestions++;
    if(this.haemoglobinDate.value) answeredQuestions++;

    const percent = answeredQuestions / totalQuestions * 100;

    const progressBar = this.element.querySelector('.purple-progress-bar .item')
    progressBar.style.width = percent+'%'
  }

  defineInputs(){
    this.drink = document.querySelector('#drinking-habits .yes-no-buttons input[name="drinking_alcohol"]');
    this.drinkDate = document.querySelector('#drinking-habits input[name="last_drank_at"]');
    this.drinkUnits = document.querySelector('#drinking-habits input[name="alcohol_weekly_count"]');
    this.smoke = document.querySelector('#smoking-habits .yes-no-buttons input[name="smoking"]');
    this.smokeDate = document.querySelector('#smoking-habits input[name="last_smoked_at"]');
    this.smokeUnits = document.querySelector('#smoking-habits input[name="cigarette_daily_count"]');
    this.anemia = document.querySelector('#anemia .yes-no-buttons input[name="anemia"]');
    this.iron = document.querySelector('#anemia .yes-no-buttons input[name="iron_supplements"]');
    this.haemoglobin = document.querySelector('#anemia input[name="haemoglobin"]');
    this.haemoglobinDate = document.querySelector('#anemia input[name="haemoglobin_taken_at"]');
  }

  sanitizeNumberInput(e){
    sanitizeNumberInput(e, 3)
  }
}
